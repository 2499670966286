import { toast } from 'react-toastify';

export const showNotification = (message, type = 'info') => {
  const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  switch (type) {
    case 'success':
      toast.success(message, toastOptions);
      break;
    case 'error':
      toast.error(message, toastOptions);
      break;
    case 'info':
    default:
      toast.info(message, toastOptions);
      break;
  }
};

export const showError = (error, customMessage = null) => {
  console.error('Detailed error:', error);
  const message = customMessage || error.message || 'An unexpected error occurred. Please try again.';
  showNotification(message, 'error');
};