import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Clock, Star, Trash2, Search } from 'lucide-react';

const SearchHistoryAndFavorites = ({ searchHistory, favorites, onSelect, onRemoveFromHistory, onRemoveFromFavorites }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md p-4 md:p-6 mb-4 md:mb-6">
      <h2 className="text-xl md:text-2xl font-bold text-[#1a1818] mb-3 md:mb-4">Search History and Favorites</h2>
      
      <div className="mb-4 md:mb-6">
        <h3 className="text-base md:text-lg font-semibold mb-2 flex items-center">
          <Clock className="mr-2" size={isMobile ? 18 : 24} /> Recent Searches
        </h3>
        {searchHistory.length > 0 ? (
          <ul className="space-y-2">
            {searchHistory.map((item, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.05 }}
                className="flex items-center justify-between py-2 border-b"
              >
                <button
                  onClick={() => onSelect(item.part_number)}
                  className="text-[#1a1818] hover:text-[#fed402] transition-colors duration-200 flex items-center text-sm md:text-base"
                >
                  <Search size={isMobile ? 14 : 16} className="mr-2" />
                  <span className="truncate max-w-[150px] md:max-w-none">{item.part_number}</span>
                  <span className="ml-2 text-xs text-gray-500 hidden md:inline">({item.search_type})</span>
                </button>
                <button
                  onClick={() => onRemoveFromHistory(item.part_number)}
                  className="text-red-500 hover:text-red-700 transition-colors duration-200 ml-2"
                  aria-label="Remove from history"
                >
                  <Trash2 size={isMobile ? 14 : 16} />
                </button>
              </motion.li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500 italic text-sm md:text-base">No recent searches</p>
        )}
      </div>
      
      <div>
        <h3 className="text-base md:text-lg font-semibold mb-2 flex items-center">
          <Star className="mr-2" size={isMobile ? 18 : 24} /> Favorites
        </h3>
        {favorites.length > 0 ? (
          <ul className="space-y-2">
            {favorites.map((item, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.05 }}
                className="flex items-center justify-between py-2 border-b"
              >
                <button
                  onClick={() => onSelect(item)}
                  className="text-[#1a1818] hover:text-[#fed402] transition-colors duration-200 flex items-center text-sm md:text-base"
                >
                  <Star size={isMobile ? 14 : 16} className="mr-2 text-[#fed402]" />
                  <span className="truncate max-w-[150px] md:max-w-none">{item}</span>
                </button>
                <button
                  onClick={() => onRemoveFromFavorites(item)}
                  className="text-red-500 hover:text-red-700 transition-colors duration-200 ml-2"
                  aria-label="Remove from favorites"
                >
                  <Trash2 size={isMobile ? 14 : 16} />
                </button>
              </motion.li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500 italic text-sm md:text-base">No favorites added</p>
        )}
      </div>
    </div>
  );
};

export default SearchHistoryAndFavorites;