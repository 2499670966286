import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeft, Upload, AlertCircle, X, Download, Trash2, Plus, Minus, ShoppingBag, Loader } from 'lucide-react';
import { toast } from 'react-toastify';
import { supabase } from '../supabaseClient';
import * as XLSX from 'xlsx';
import Navigation from './Navigation';
import { showNotification, showError } from '../utils/notificationHandler';

const LoadingOverlay = ({ message }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center">
      <Loader className="w-12 h-12 text-[#fed402] animate-spin" />
      <p className="mt-4 text-lg font-semibold text-[#1a1818]">{message}</p>
    </div>
  </div>
);

const BulkUpload = () => {
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [isReviewing, setIsReviewing] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const history = useHistory();

  const placeholderImageUrl = "https://syd1.digitaloceanspaces.com/cdn-assets.mhs/images/placeholders/mhs_placeholder.png";

  useEffect(() => {
    const fetchAccountId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('accounts')
          .select('id')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching account ID:', error);
        } else {
          setAccountId(data.id);
        }
      }
    };

    fetchAccountId();
  }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleFile = (file) => {
    if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        file.type !== 'application/vnd.ms-excel' &&
        file.type !== 'text/csv') {
          showNotification('Please upload a valid Excel or CSV file', 'error');
      return;
    }
    setFile(file);
  };

  const handleUpload = async () => {
    if (!file) {
      showNotification('Please select a file to upload', 'error');
      return;
    }

    setLoading(true);

    try {
      const data = await readFileAsync(file);
      const orders = parseFileData(data);
      const enrichedOrders = await enrichOrderData(orders);
      setUploadedData(enrichedOrders);
      setIsReviewing(true);
      showNotification('File uploaded and processed successfully. Please review your order.', 'success');
    } catch (error) {
      console.error('Error processing file:', error);
      showError(error, 'Failed to process file. Please check the file and try again.');
    } finally {
      setLoading(false);
    }
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        resolve(data);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });
  };

  const parseFileData = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    return XLSX.utils.sheet_to_json(sheet, { defval: null });
  };

  const enrichOrderData = async (orders) => {
    const enrichedOrders = [];
    setIsSearching(true);
    for (const order of orders) {
      if (!order.PartNumber) {
        console.error('Skipping row without PartNumber');
        continue;
      }
      try {
        const response = await fetch('/api/enquire/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            partNumber: order.PartNumber,
            account_id: accountId
          }),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log('Full API response for', order.PartNumber, ':', JSON.stringify(data, null, 2));
  
        if (data.cross_references && data.cross_references.length > 0) {
          // This is an alternative part number
          const primaryPart = data.cross_references[0];
          enrichedOrders.push({
            PartNumber: order.PartNumber,
            PrimaryPartNumber: primaryPart.prod_code,
            Description: primaryPart.main_description,
            Quantity: order.Quantity || null,
            Price: primaryPart.buy_price || primaryPart.base_price || null,
            QuantityAvailable: primaryPart.qty_available || 0,
            SmallImageUrl: primaryPart.small_image_url || placeholderImageUrl,
            isAlternative: true,
            isFound: true
          });
        } else if (data.prod_code || data.bom_code) {
          // This is a primary part number
          enrichedOrders.push({
            PartNumber: order.PartNumber,
            Description: data.Description || 'N/A',
            Quantity: order.Quantity || null,
            Price: data.buy_price || data.base_price || null,
            QuantityAvailable: data.qty_available || 0,
            SmallImageUrl: data.small_image_url || placeholderImageUrl,
            isAlternative: false,
            isFound: true
          });
        } else {
          console.error('Unexpected API response structure:', data);
          throw new Error('Unexpected API response structure');
        }
      } catch (error) {
        console.error(`Error fetching details for ${order.PartNumber}:`, error);
        enrichedOrders.push({
          PartNumber: order.PartNumber,
          Description: 'Part number not found',
          Quantity: order.Quantity || null,
          Price: null,
          QuantityAvailable: 0,
          SmallImageUrl: placeholderImageUrl,
          isAlternative: false,
          isFound: false
        });
      }
    }
    setIsSearching(false);
    return enrichedOrders;
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedData = [...uploadedData];
    updatedData[index].Quantity = newQuantity !== null ? Math.max(1, newQuantity) : null;
    setUploadedData(updatedData);
  };

  const handleRemoveLine = (index) => {
    const updatedData = uploadedData.filter((_, i) => i !== index);
    setUploadedData(updatedData);
  };

  const handleProceedToCheckout = () => {
    const cartItems = uploadedData.filter(item => item.isFound && item.Quantity && item.Quantity > 0).map(item => ({
      partNumber: item.PartNumber,
      description: item.Description,
      quantity: item.Quantity,
      price: item.Price,
    }));

    if (cartItems.length === 0) {
      showNotification('Please add quantities to at least one valid item before proceeding to checkout.', 'error');
      return;
    }

    localStorage.setItem('cart', JSON.stringify(cartItems));
    history.push('/checkout');
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      showNotification('Successfully signed out!', 'success');
      history.push('/login');
    } catch (error) {
      showError(error, 'Login failed. Please check your credentials and try again.');
    }
  };

  const downloadTemplate = (format) => {
    const data = [
      { PartNumber: 'ABC123', Quantity: 10, Description: 'Sample Part 1', Price: 9.99 },
      { PartNumber: 'XYZ789', Quantity: 5, Description: 'Sample Part 2', Price: 14.99 }
    ];

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");

    if (format === 'xlsx') {
      XLSX.writeFile(wb, "bulk_order_template.xlsx");
    } else if (format === 'csv') {
      XLSX.writeFile(wb, "bulk_order_template.csv", { bookType: "csv" });
    }
  };

  return (
    <div className="min-h-screen bg-[#1a1818]">
      <Navigation onSignOut={handleSignOut} />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-extrabold text-[#fed402] text-center mb-12"
        >
          Bulk Upload Orders
        </motion.h1>
        
        {!isReviewing ? (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-lg shadow-md p-6 mb-8"
          >
            <div 
              className={`mb-6 border-2 border-dashed rounded-lg p-10 text-center ${dragging ? 'border-[#fed402] bg-[#fed402]/10' : 'border-gray-300'}`}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                type="file"
                id="file-upload"
                className="hidden"
                onChange={handleFileChange}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <label
                htmlFor="file-upload"
                className="cursor-pointer flex flex-col items-center justify-center"
              >
                <Upload className="w-12 h-12 text-gray-400 mb-4" />
                <p className="text-lg font-semibold text-gray-700 mb-2">
                  {file ? file.name : 'Drag and drop your file here'}
                </p>
                <p className="text-sm text-gray-500">
                  {file ? 'Click to change file' : 'or click to select'}
                </p>
              </label>
            </div>
            
            {file && (
              <div className="flex items-center justify-between bg-gray-100 p-3 rounded-md mb-4">
                <span className="text-sm font-medium text-gray-700">{file.name}</span>
                <button
                  onClick={() => setFile(null)}
                  className="text-red-500 hover:text-red-700 transition-colors duration-200"
                >
                  <X size={20} />
                </button>
              </div>
            )}
            
            <div className="flex items-center justify-between">
              <button
                onClick={handleUpload}
                disabled={loading || !file}
                className={`bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center ${loading || !file ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {loading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  <>
                    <Upload className="mr-2" />
                    Upload and Review
                  </>
                )}
              </button>
            </div>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-lg shadow-md p-6 mb-8"
          >
            <h2 className="text-2xl font-bold text-[#1a1818] mb-4">Review Your Order</h2>
            {uploadedData.map((item, index) => (
              <div 
              key={index} 
              className={`flex items-center justify-between py-2 border-b ${
                !item.isFound ? 'bg-red-100' : item.isAlternative ? 'bg-yellow-100' : ''
              }`}
            >
              <div className="flex items-center">
                <img 
                  src={item.SmallImageUrl}
                  alt={item.PartNumber}
                  className="w-12 h-12 object-contain mr-4"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = placeholderImageUrl;
                  }}
                />
                <div>
                  <p className="font-semibold">{item.PartNumber}</p>
                  {item.isAlternative && (
                    <p className="text-sm text-yellow-600">
                      Alternative for: {item.PrimaryPartNumber}
                    </p>
                  )}
                  <p className={`text-sm ${item.isFound ? 'text-gray-600' : 'text-red-600 font-semibold'}`}>
                    {item.Description}
                  </p>
                  {item.isFound ? (
                    <>
                      <p className="text-sm text-gray-600">Price: ${item.Price ? item.Price.toFixed(2) : 'N/A'}</p>
                      <p className="text-sm text-gray-600">Available: {item.QuantityAvailable}</p>
                    </>
                  ) : (
                    <p className="text-sm text-red-600">Part number not found</p>
                  )}
                </div>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => handleQuantityChange(index, (item.Quantity || 0) - 1)}
                  className="text-[#1a1818] hover:text-[#fed402] transition-colors duration-200"
                  disabled={!item.isFound}
                >
                  <Minus size={20} />
                </button>
                <input
                  type="number"
                  value={item.Quantity || ''}
                  onChange={(e) => handleQuantityChange(index, e.target.value === '' ? null : parseInt(e.target.value, 10))}
                  className="mx-2 w-16 text-center border rounded-md"
                  min="1"
                  disabled={!item.isFound}
                />
                <button
                  onClick={() => handleQuantityChange(index, (item.Quantity || 0) + 1)}
                  className="text-[#1a1818] hover:text-[#fed402] transition-colors duration-200"
                  disabled={!item.isFound}
                >
                  <Plus size={20} />
                </button>
                <button
                  onClick={() => handleRemoveLine(index)}
                  className="ml-4 text-red-500 hover:text-red-700 transition-colors duration-200"
                >
                  <Trash2 size={20} />
                </button>
              </div>
            </div>
          ))}
          <div className="mt-6 flex justify-between">
            <button
              onClick={() => setIsReviewing(false)}
              className="bg-gray-300 hover:bg-gray-400 text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Back to Upload
            </button>
            <button
              onClick={handleProceedToCheckout}
              className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
            >
              <ShoppingBag className="mr-2" />
              Proceed to Checkout
            </button>
          </div>
        </motion.div>
      )}
      
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        className="bg-[#fed402] rounded-lg p-4 mb-8"
      >
        <div className="flex items-start">
          <AlertCircle className="flex-shrink-0 h-5 w-5 text-[#1a1818] mt-0.5" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-[#1a1818]">Important Notes:</h3>
            <div className="mt-2 text-sm text-[#1a1818]">
              <ul className="list-disc pl-5 space-y-1">
                <li>File must be in CSV or Excel format</li>
                <li>Required column: PartNumber</li>
                <li>Optional column: Quantity</li>
                <li>Description and Price will be fetched from our database</li>
                <li>If Quantity is not provided, you can enter it during review</li>
                <li>Maximum 1000 lines per upload</li>
              </ul>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        className="bg-white rounded-lg shadow-md p-6 mb-8"
      >
        <h2 className="text-xl font-bold text-[#1a1818] mb-4">Download Template</h2>
        <p className="text-gray-600 mb-4">Use these templates to ensure your bulk upload is formatted correctly:</p>
        <div className="flex space-x-4">
          <button
            onClick={() => downloadTemplate('xlsx')}
            className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
          >
            <Download className="mr-2" />
            Excel Template
          </button>
          <button
            onClick={() => downloadTemplate('csv')}
            className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
          >
            <Download className="mr-2" />
            CSV Template
          </button>
        </div>
      </motion.div>

      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        className="mt-8"
      >
        <button
          onClick={() => history.push('/dashboard')}
          className="w-full flex items-center justify-center py-3 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Dashboard
        </button>
      </motion.div>

      <AnimatePresence>
        {isSearching && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoadingOverlay message="Searching for parts..." />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  </div>
);
};

export default BulkUpload;