import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, ArrowUp, ArrowDown, Save, X, Edit, Search, Eye } from 'lucide-react';
import { supabase } from '../supabaseClient';
import Navigation from './Navigation';
import ReactECharts from 'echarts-for-react';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { showNotification, showError } from '../utils/notificationHandler';

const ORDER_STATUSES = [
  'Pending',
  'Complete',
  'In Progress',
  'Cancelled',
  'On Hold',
  'On Order',
];

const SHIPPING_STATUSES = [
  'Waiting For Collection',
  'Shipped',
  'Delayed',
];

const LoadingOverlay = ({ message }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#fed402]"></div>
      <p className="mt-4 text-lg font-semibold text-[#1a1818]">{message}</p>
    </div>
  </div>
);

const StaffArea = () => {
  const [orders, setOrders] = useState([]);
  const [chartType, setChartType] = useState('Order Status');
  const [timeRange, setTimeRange] = useState('All Time');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filterCompany, setFilterCompany] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [editingOrder, setEditingOrder] = useState(null);
  const [viewingOrder, setViewingOrder] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(30);
  const [totalOrders, setTotalOrders] = useState(0);

  useEffect(() => {
    fetchOrders();
    fetchAccounts();
  }, [timeRange, sortField, sortDirection, filterCompany, searchTerm, limit]);

  const fetchAccounts = async () => {
    try {
      const { data, error } = await supabase
        .from('accounts')
        .select('id, company_name')
        .order('company_name');

      if (error) throw error;
      setAccounts(data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      showError(error, 'Failed to fetch accounts');
    }
  };

  const fetchOrders = async () => {
    setLoading(true);
    setError(null);
    try {
      let query = supabase
        .from('orders')
        .select(
          `
          id,
          order_number,
          status,
          created_at,
          cart_items,
          user_id,
          notes,
          mhs_reference,
          shipping_status,
          connote,
          accounts:user_id (
            id,
            company_name,
            mhs_account_number
          )
        `,
          { count: 'exact' }
        )
        .order(sortField, { ascending: sortDirection === 'asc' })
        .limit(limit);

      if (filterCompany) {
        query = query.eq('accounts.company_name', filterCompany);
      }

      if (searchTerm) {
        query = query.or(
          `order_number.ilike.%${searchTerm}%,mhs_reference.ilike.%${searchTerm}%`
        );
      }

      if (timeRange === 'Current Month') {
        const startDate = startOfMonth(new Date());
        const endDate = endOfMonth(new Date());
        query = query
          .gte('created_at', startDate.toISOString())
          .lte('created_at', endDate.toISOString());
      } else if (timeRange === 'Last Month') {
        const lastMonthDate = new Date();
        lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
        const startDate = startOfMonth(lastMonthDate);
        const endDate = endOfMonth(lastMonthDate);
        query = query
          .gte('created_at', startDate.toISOString())
          .lte('created_at', endDate.toISOString());
      }

      const { data, error, count } = await query;

      if (error) throw error;

      console.log('Fetched orders:', data);

      setOrders(data || []);
      setTotalOrders(count || 0);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError('Failed to fetch orders. Please try again.');
      showError(error, 'Failed to fetch orders. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditOrder = (order) => {
    console.log('Editing order:', order.id);
    setEditingOrder({ ...order });
    setIsEditModalOpen(true);
  };

  const handleViewOrder = (order) => {
    console.log('Viewing order:', order.id);
    setViewingOrder({ ...order });
    setIsViewModalOpen(true);
  };

  const handleSaveOrder = async () => {
    try {
      const { data, error } = await supabase
        .from('orders')
        .update({
          status: editingOrder.status,
          notes: editingOrder.notes,
          mhs_reference: editingOrder.mhs_reference,
          shipping_status: editingOrder.shipping_status,
          connote: editingOrder.connote,
        })
        .eq('id', editingOrder.id)
        .select(
          `
          id,
          order_number,
          status,
          created_at,
          cart_items,
          user_id,
          notes,
          mhs_reference,
          shipping_status,
          connote,
          accounts:user_id (
            id,
            company_name,
            mhs_account_number
          )
        `
        );

      if (error) throw error;

      // Create a notification for the customer
      await supabase.from('notifications').insert({
        user_id: editingOrder.user_id,
        message: `Order #${editingOrder.order_number} has been updated. New status: ${editingOrder.status}`,
        read: false,
      });

      setOrders(
        orders.map((order) => (order.id === editingOrder.id ? data[0] : order))
      );
      setEditingOrder(null);
      setIsEditModalOpen(false);
      showNotification('Order updated successfully', 'success');
    } catch (error) {
      console.error('Error updating order:', error);
      showError(error, 'Failed to update order');
    }
  };

  const handleCancelEdit = () => {
    setEditingOrder(null);
    setIsEditModalOpen(false);
  };

  const handleCloseView = () => {
    setViewingOrder(null);
    setIsViewModalOpen(false);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      showNotification('Successfully signed out!', 'success');
      history.push('/login');
    } catch (error) {
      showError(error, 'Failed to sign out');
    }
  };

  const getChartOption = () => {
    if (chartType === 'Order Status') {
      return {
        title: {
          text: 'Order Status Distribution',
          left: 'center',
          textStyle: { color: '#fed402' },
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          textStyle: { color: '#fed402' },
        },
        series: [
          {
            name: 'Order Status',
            type: 'pie',
            radius: '50%',
            data: ORDER_STATUSES.map((status) => ({
              value: orders.filter((order) => order.status === status).length,
              name: status,
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            label: {
              color: '#fed402',
            },
          },
        ],
      };
    } else if (chartType === 'Number of Orders') {
      const ordersByDate = {};
      orders.forEach((order) => {
        const date = format(new Date(order.created_at), 'yyyy-MM-dd');
        if (ordersByDate[date]) {
          ordersByDate[date] += 1;
        } else {
          ordersByDate[date] = 1;
        }
      });

      const dates = Object.keys(ordersByDate).sort();

      return {
        title: {
          text: 'Number of Orders Over Time',
          left: 'center',
          textStyle: { color: '#fed402' },
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: dates,
          axisLabel: { color: '#fed402' },
        },
        yAxis: {
          type: 'value',
          axisLabel: { color: '#fed402' },
        },
        series: [
          {
            data: dates.map((date) => ordersByDate[date]),
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#fed402',
            },
          },
        ],
      };
    }
  };

  const loadMoreOrders = () => {
    setLimit(limit + 30);
  };

  return (
    <div className="min-h-screen bg-[#1a1818] text-white">
      <Navigation onSignOut={handleSignOut} />
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-extrabold text-[#fed402] text-center mb-12"
        >
          Staff Area
        </motion.h1>

        {/* Chart Type Selection */}
        <div className="mb-4 flex space-x-4 justify-center">
          <button
            onClick={() => setChartType('Order Status')}
            className={`py-2 px-4 rounded ${
              chartType === 'Order Status'
                ? 'bg-[#fed402] text-black'
                : 'bg-gray-700 text-white'
            }`}
          >
            Order Status
          </button>
          <button
            onClick={() => setChartType('Number of Orders')}
            className={`py-2 px-4 rounded ${
              chartType === 'Number of Orders'
                ? 'bg-[#fed402] text-black'
                : 'bg-gray-700 text-white'
            }`}
          >
            Number of Orders
          </button>
        </div>

        {/* Chart */}
        <div className="mb-8">
          <ReactECharts option={getChartOption()} style={{ height: '400px' }} />
        </div>

        {/* Search and Filters */}
        <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="w-full md:w-1/3 mb-2 md:mb-0">
            <label
              htmlFor="search"
              className="block text-sm font-medium text-[#fed402] mb-1"
            >
              Search Orders:
            </label>
            <div className="relative text-black">
              <input
                id="search"
                type="text"
                placeholder="Order Number or MHS Reference"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm"
              />
              <Search className="absolute right-3 top-2.5 text-gray-400" />
            </div>
          </div>
          <div className="w-full md:w-auto mb-2 md:mb-0">
            <label
              htmlFor="time-range"
              className="block text-sm font-medium text-[#fed402] mb-1"
            >
              Select Time Range:
            </label>
            <select
              id="time-range"
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
              className="mt-1 block w-full md:w-auto py-2 px-3 border border-gray-300 bg-white text-black rounded-md shadow-sm sm:text-sm"
            >
              <option value="All Time">All Time</option>
              <option value="Current Month">Current Month</option>
              <option value="Last Month">Last Month</option>
            </select>
          </div>
          <div className="w-full md:w-auto">
            <label
              htmlFor="company-filter"
              className="block text-sm font-medium text-[#fed402] mb-1"
            >
              Filter by Company:
            </label>
            <select
              id="company-filter"
              value={filterCompany}
              onChange={(e) => setFilterCompany(e.target.value)}
              className="mt-1 block w-full md:w-auto py-2 px-3 border border-gray-300 bg-white text-black rounded-md shadow-sm sm:text-sm"
            >
              <option value="">All Companies</option>
              {accounts.map((account) => (
                <option key={account.id} value={account.company_name}>
                  {account.company_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Orders Table */}
        {loading ? (
          <LoadingOverlay message="Loading orders..." />
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : orders.length === 0 ? (
          <p className="text-center text-gray-500">No orders available.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 text-black">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                  <th
                    onClick={() => handleSort('order_number')}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  >
                    Order #
                    {sortField === 'order_number' &&
                      (sortDirection === 'asc' ? (
                        <ArrowUp className="inline ml-1" />
                      ) : (
                        <ArrowDown className="inline ml-1" />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSort('created_at')}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  >
                    Date
                    {sortField === 'created_at' &&
                      (sortDirection === 'asc' ? (
                        <ArrowUp className="inline ml-1" />
                      ) : (
                        <ArrowDown className="inline ml-1" />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSort('accounts.company_name')}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  >
                    Company
                    {sortField === 'accounts.company_name' &&
                      (sortDirection === 'asc' ? (
                        <ArrowUp className="inline ml-1" />
                      ) : (
                        <ArrowDown className="inline ml-1" />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSort('total_amount')}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  >
                    Total
                    {sortField === 'total_amount' &&
                      (sortDirection === 'asc' ? (
                        <ArrowUp className="inline ml-1" />
                      ) : (
                        <ArrowDown className="inline ml-1" />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSort('status')}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  >
                    Status
                    {sortField === 'status' &&
                      (sortDirection === 'asc' ? (
                        <ArrowUp className="inline ml-1" />
                      ) : (
                        <ArrowDown className="inline ml-1" />
                      ))}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-black">
                {orders.map((order) => (
                  <tr 
                    key={order.id}
                    className="hover:bg-gray-100"
                  >
                    <td className="px-6 py-4 whitespace-nowrap flex space-x-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleViewOrder(order);
                        }}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        <Eye className="h-5 w-5" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditOrder(order);
                        }}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        <Edit className="h-5 w-5" />
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{order.order_number}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {format(new Date(order.created_at), 'yyyy-MM-dd')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{order.accounts?.company_name}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      ${order.cart_items.reduce((sum, item) => sum + item.price * item.quantity, 0).toFixed(2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{order.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Load More Button */}
        {orders.length < totalOrders && (
          <div className="mt-4 flex justify-center">
            <button
              onClick={loadMoreOrders}
              className="bg-[#fed402] hover:bg-[#e5c102] text-black font-bold py-2 px-4 rounded"
            >
              Load More
            </button>
          </div>
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-8"
        >
          <button
            onClick={() => history.push('/dashboard')}
            className="w-full flex items-center justify-center py-3 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </button>
        </motion.div>
      </div>

      {/* View Order Modal */}
      {isViewModalOpen && viewingOrder && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Order Details</h3>
                <div className="mt-2 text-gray-700">
                  <p><strong>Order Number:</strong> {viewingOrder.order_number}</p>
                  <p><strong>Date:</strong> {format(new Date(viewingOrder.created_at), 'yyyy-MM-dd')}</p>
                  <p><strong>Company:</strong> {viewingOrder.accounts?.company_name}</p>
                  <p><strong>Status:</strong> {viewingOrder.status}</p>
                  <p><strong>Shipping Status:</strong> {viewingOrder.shipping_status}</p>
                  <p><strong>MHS Reference:</strong> {viewingOrder.mhs_reference}</p>
                  <p><strong>ConNote:</strong> {viewingOrder.connote}</p>
                  <p><strong>Notes:</strong> {viewingOrder.notes}</p>
                  <p><strong>Total:</strong> ${viewingOrder.cart_items.reduce((sum, item) => sum + item.price * item.quantity, 0).toFixed(2)}</p>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleCloseView}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Order Modal */}
{isEditModalOpen && editingOrder && (
  <div className="fixed z-10 inset-0 overflow-y-auto">
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Edit Order</h3>
          <div className="mt-2">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Status</label>
              <select
                value={editingOrder.status}
                onChange={(e) => setEditingOrder({ ...editingOrder, status: e.target.value })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm text-black"
              >
                {ORDER_STATUSES.map((status) => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Shipping Status</label>
              <select
                value={editingOrder.shipping_status}
                onChange={(e) => setEditingOrder({ ...editingOrder, shipping_status: e.target.value })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm text-black"
              >
                {SHIPPING_STATUSES.map((status) => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">MHS Reference</label>
              <input
                type="text"
                value={editingOrder.mhs_reference || ''}
                onChange={(e) => setEditingOrder({ ...editingOrder, mhs_reference: e.target.value })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm text-black"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">ConNote</label>
              <input
                type="text"
                value={editingOrder.connote || ''}
                onChange={(e) => setEditingOrder({ ...editingOrder, connote: e.target.value })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm text-black"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Notes</label>
              <textarea
                value={editingOrder.notes || ''}
                onChange={(e) => setEditingOrder({ ...editingOrder, notes: e.target.value })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm text-black"
                rows="3"
              ></textarea>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#fed402] text-base font-medium text-black hover:bg-[#e5c102] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleSaveOrder}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default StaffArea;