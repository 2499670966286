import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Navigation from './Navigation';
import { useHistory } from 'react-router-dom';
import { Download, FileText, ArrowLeft } from 'lucide-react';

const StauffClamps = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="min-h-screen bg-[#1a1818] text-[#fed402]">
      <Navigation />

      <div className="px-4 py-6 md:p-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-2xl md:text-4xl font-extrabold text-center mb-2 md:mb-4"
        >
          STAUFF Product Catalogue in PDF Format
        </motion.h1>
        <motion.h2
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-lg md:text-2xl text-center mb-6 md:mb-12"
        >
          Download entire STAUFF product catalogue in PDF format
        </motion.h2>

        <div className="flex justify-center">
          <motion.a
            href="https://syd1.digitaloceanspaces.com/cdn-assets.mhs/pdfcatalogs/STAUFF-Catalogue-1-STAUFF-Clamps-English.pdf"
            target="_blank"
            rel="noopener noreferrer"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="w-full md:w-auto bg-[#fed402] text-[#1a1818] px-4 py-2 rounded-full font-semibold hover:bg-[#e0bc02] transition duration-200 flex items-center justify-center"
          >
            <FileText className="mr-2 w-4 h-4 md:w-5 md:h-5" /> 
            <span className="text-sm md:text-base">Catalogue STAUFF Clamps</span> 
            <Download className="ml-2 w-4 h-4 md:w-5 md:h-5" />
          </motion.a>
        </div>

        <p className="text-center text-xs md:text-sm mt-2 opacity-80">
          Size: 38.00 MB
        </p>

        {/* Back Button */}
        <div className="mt-8 md:mt-12 text-center">
          <button
            onClick={() => history.goBack()}
            className="w-full md:w-auto bg-[#fed402] text-[#1a1818] px-4 py-2 rounded-full font-semibold hover:bg-[#e0bc02] transition duration-200 flex items-center justify-center"
          >
            <ArrowLeft className="mr-2 w-4 h-4 md:w-5 md:h-5" />
            <span className="text-sm md:text-base">Back</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StauffClamps;