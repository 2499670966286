import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import Navigation from './Navigation';

const ProductDocuments = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const documents = [
    {
      id: 1,
      title: 'STAUFF Product Catalogues',
      description: 'Select STAUFF product group to view, download product catalogues',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/stauff_catalogue.jpg`,
      linkUrl: '/stauff-catalogues',
    },
    {
      id: 2,
      title: 'Product Approvals',
      description: 'Download certificates and approvals for STAUFF products',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/product_approvals.jpg`,
    },
    {
      id: 3,
      title: 'STAUFF Buyers Guide',
      description: 'Download STAUFF Aus Buyers Guide',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/buyers_guide.jpg`,
    },
  ];

  return (
    <div className="min-h-screen bg-[#1a1818] text-[#fed402]">
      <Navigation />

      <div className="p-4 md:p-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-2xl md:text-4xl font-extrabold text-center mb-6 md:mb-12"
        >
          Product Documents
        </motion.h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          {documents.map((doc) => (
            <ProductTile 
              key={doc.id}
              imageUrl={doc.imageUrl}
              title={doc.title}
              description={doc.description}
              linkUrl={doc.linkUrl}
              isMobile={isMobile}
            />
          ))}
        </div>

        <div className="mt-8 md:mt-12 text-center">
          <button
            onClick={() => history.push('/dashboard')}
            className="w-full md:w-auto bg-[#fed402] text-[#1a1818] px-4 py-2 rounded-full font-semibold hover:bg-[#e0bc02] transition duration-200"
          >
            Back to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

const ProductTile = ({ imageUrl, title, description, linkUrl, isMobile }) => {
  const history = useHistory();

  return (
    <motion.div
      whileHover={{ scale: isMobile ? 1 : 1.05 }}
      className="bg-[#fed402] rounded-xl shadow-xl overflow-hidden cursor-pointer"
      onClick={() => linkUrl && history.push(linkUrl)}
    >
      <img src={imageUrl} alt={title} className="w-full h-32 md:h-48 object-cover" />
      <div className="p-4 md:p-6">
        <h3 className="text-base md:text-lg font-bold text-[#1a1818] mb-1 md:mb-2">{title}</h3>
        <p className="text-xs md:text-sm text-[#1a1818] opacity-80">{description}</p>
      </div>
    </motion.div>
  );
};

export default ProductDocuments;