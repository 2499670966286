import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Bell, Check } from 'lucide-react';
import { supabase } from '../supabaseClient';
import { format } from 'date-fns';

const NotificationTimeline = ({ onClose }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching notifications:', error);
      } else {
        setNotifications(data);
      }
    }
  };

  const markAsRead = async (notificationId) => {
    const { error } = await supabase
      .from('notifications')
      .update({ read: true })
      .eq('id', notificationId);

    if (error) {
      console.error('Error marking notification as read:', error);
    } else {
      setNotifications(notifications.map(notif =>
        notif.id === notificationId ? { ...notif, read: true } : notif
      ));
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="fixed right-0 top-16 w-96 bg-white shadow-xl rounded-lg p-4 max-h-[80vh] overflow-y-auto"
    >
      <h2 className="text-xl font-bold mb-4 flex items-center">
        <Bell className="mr-2" /> Notifications
      </h2>
      {notifications.length === 0 ? (
        <p>No notifications</p>
      ) : (
        <ul className="space-y-4">
          {notifications.map((notification) => (
            <li
              key={notification.id}
              className={`p-3 rounded-lg ${
                notification.read ? 'bg-gray-100' : 'bg-blue-100'
              }`}
            >
              <p className="font-semibold">{notification.message}</p>
              <p className="text-sm text-gray-600">
                {format(new Date(notification.created_at), 'PPpp')}
              </p>
              {!notification.read && (
                <button
                  onClick={() => markAsRead(notification.id)}
                  className="mt-2 text-blue-600 hover:text-blue-800 flex items-center"
                >
                  <Check className="mr-1" size={16} /> Mark as read
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
      <button
        onClick={onClose}
        className="mt-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
      >
        Close
      </button>
    </motion.div>
  );
};

export default NotificationTimeline;