import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { supabase } from '../supabaseClient';
import Navigation from './Navigation';
import { QrCode, X, ShoppingBag } from 'lucide-react';
import { Html5Qrcode } from 'html5-qrcode';
import { showNotification, showError } from '../utils/notificationHandler';
import { isMobile, isTablet } from 'react-device-detect'; // Import device detection
import { DotLoader } from 'react-spinners';

const Scanner = () => {
  const [isScanning, setIsScanning] = useState(false);
  const [html5QrCode, setHtml5QrCode] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [isProcessingScan, setIsProcessingScan] = useState(false);
  const [scannedItem, setScannedItem] = useState(null);
  const history = useHistory();

  const placeholderImageUrl =
    'https://syd1.digitaloceanspaces.com/cdn-assets.mhs/images/placeholders/mhs_placeholder.png';

  // Fetch account ID on component mount
  useEffect(() => {
    const fetchAccountId = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('accounts')
          .select('id')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching account ID:', error);
        } else if (data) {
          setAccountId(data.id);
        }
      }
    };

    fetchAccountId();
  }, []);

  const scannerConfig = useMemo(() => ({ fps: 10, qrbox: { width: 250, height: 250 } }), []);

  const onScanSuccess = useCallback(
    async (decodedText) => {
      if (isProcessingScan) {
        return;
      }
      setIsProcessingScan(true);

      console.log('Scan successful:', decodedText);

      try {
        if (!accountId) {
          showNotification('Account ID not available', 'error');
          return;
        }

        const response = await fetch('/api/enquire/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            partNumber: decodedText,
            account_id: accountId,
          }),
        });

        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('Item not found');
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        let itemDetails = {};

        if (data.cross_references && data.cross_references.length > 0) {
          // Alternative part number
          const primaryPart = data.cross_references[0];
          itemDetails = {
            id: Date.now(),
            PartNumber: decodedText,
            PrimaryPartNumber: primaryPart.prod_code,
            Description: primaryPart.main_description,
            Quantity: 1,
            Price: primaryPart.buy_price || primaryPart.base_price || null,
            QuantityAvailable: primaryPart.qty_available || 0,
            SmallImageUrl: primaryPart.small_image_url || placeholderImageUrl,
            isAlternative: true,
            isFound: true,
          };
        } else if (data.prod_code || data.bom_code) {
          // Primary part number
          itemDetails = {
            id: Date.now(),
            PartNumber: decodedText,
            Description: data.Description || 'N/A',
            Quantity: 1,
            Price: data.buy_price || data.base_price || null,
            QuantityAvailable: data.qty_available || 0,
            SmallImageUrl: data.small_image_url || placeholderImageUrl,
            isAlternative: false,
            isFound: true,
          };
        } else {
          throw new Error('Part number not found');
        }

        // Show confirmation modal
        setScannedItem(itemDetails);
      } catch (error) {
        console.error(`Error fetching details for ${decodedText}:`, error);
        showError(error, 'Failed to fetch item details. Please try again.');
      } finally {
        setIsProcessingScan(false);
      }
    },
    [accountId, isProcessingScan]
  );

  const onScanFailure = useCallback((error) => {
    console.warn('Scan failed:', error);
  }, []);

  // Initialize or clear scanner based on isScanning state and accountId
  useEffect(() => {
    let qrCodeScanner;

    if (isScanning && accountId) {
      qrCodeScanner = new Html5Qrcode('reader');
      setHtml5QrCode(qrCodeScanner);

      qrCodeScanner
        .start({ facingMode: 'environment' }, scannerConfig, onScanSuccess, onScanFailure)
        .catch((err) => {
          console.error('Error starting scanner:', err);
          if (err.name === 'NotAllowedError') {
            showError(err, 'Camera access denied. Please allow camera permissions.');
          } else if (err.name === 'NotFoundError') {
            showError(err, 'No camera found. Please connect a camera and try again.');
          } else {
            showError(err, 'Failed to start scanner. Please try again.');
          }
          setIsScanning(false);
        });
    }

    // Cleanup function
    return () => {
      if (qrCodeScanner) {
        qrCodeScanner
          .stop()
          .then(() => {
            qrCodeScanner.clear();
            setHtml5QrCode(null);
          })
          .catch((err) => {
            console.error('Failed to stop scanner during cleanup:', err);
          });
      }
    };
  }, [isScanning, accountId, scannerConfig, onScanSuccess, onScanFailure]);

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      showNotification('Successfully signed out!', 'success');
    } catch (error) {
      showError(error, 'Failed to sign out. Please try again.');
    }
  };

  const startScanner = () => {
    console.log('startScanner called');
    setIsScanning(true);
  };

  const stopScanner = () => {
    console.log('stopScanner called');
    setIsScanning(false);
  };

  const addToCartDirectly = (item) => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const existingItemIndex = cart.findIndex(
      (cartItem) => cartItem.partNumber === item.PartNumber
    );
    if (existingItemIndex >= 0) {
      cart[existingItemIndex].quantity += item.Quantity;
    } else {
      cart.push({
        partNumber: item.PartNumber,
        description: item.Description,
        quantity: item.Quantity,
        price: item.Price,
      });
    }
    localStorage.setItem('cart', JSON.stringify(cart));
  };

  const proceedToCheckout = () => {
    history.push('/checkout');
  };

  const ConfirmationModal = ({ item, onConfirm, onCancel }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 w-11/12 max-w-sm">
          <h2 className="text-xl font-bold mb-4">Add Item to Cart</h2>
          <div className="mb-4">
            <img src={item.SmallImageUrl} alt={item.Description} className="w-full h-auto" />
            <p className="mt-2">
              <strong>Part Number:</strong> {item.PartNumber}
            </p>
            <p>
              <strong>Description:</strong> {item.Description}
            </p>
            <p>
              <strong>Price:</strong> {item.Price ? `$${item.Price.toFixed(2)}` : 'N/A'}
            </p>
            <p>
              <strong>Available Quantity:</strong> {item.QuantityAvailable}
            </p>
          </div>
          <div className="flex justify-end">
            <button
              onClick={onCancel}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded mr-2 hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="bg-[#fed402] text-[#1a1818] px-4 py-2 rounded hover:bg-[#e0bc02]"
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Device detection logic
  const isMobileOrTablet = isMobile || isTablet;

  return (
    <div className="min-h-screen bg-[#1a1818] text-[#fed402] flex flex-col">
      <Navigation onSignOut={handleSignOut} />
      <div className="container mx-auto px-4 py-8 flex-grow">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold mb-8 text-center"
        >
          Scanner
        </motion.h1>

        {isMobileOrTablet ? (
          <div className="mb-8 flex flex-col items-center">
            {isScanning ? (
              <div className="bg-[#2a2a2a] p-4 rounded-lg shadow-lg w-full">
                <div id="reader" className="w-full"></div>
                <div className="mt-4 flex justify-center">
                  <button
                    onClick={stopScanner}
                    className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition-colors flex items-center justify-center w-full max-w-xs"
                  >
                    <X className="mr-2" />
                    Stop Scanning
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex justify-center w-full">
                <button
                  onClick={startScanner}
                  className="bg-[#fed402] text-[#1a1818] px-4 py-2 rounded-lg hover:bg-[#e0bc02] transition-colors flex items-center justify-center w-full max-w-xs"
                >
                  <QrCode className="mr-2" />
                  Start Scanning
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center text-lg">
            The scanner is only available on mobile devices and tablets.
          </div>
        )}

        <div className="mt-6 flex justify-end">
          <button
            onClick={proceedToCheckout}
            className="bg-[#fed402] text-[#1a1818] px-4 py-2 rounded-lg hover:bg-[#e0bc02] transition-colors flex items-center"
          >
            <ShoppingBag className="mr-2" />
            Proceed to Checkout
          </button>
        </div>
      </div>

      {/* Loading Overlay */}
      {isProcessingScan && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="text-center">
            {/* DotLoader Spinner */}
            <DotLoader color="#fed402" size={60} />
            <p className="text-white mt-4">Processing...</p>
          </div>
        </div>
      )}

      {/* Confirmation Modal */}
      {scannedItem && (
        <ConfirmationModal
          item={scannedItem}
          onConfirm={() => {
            addToCartDirectly(scannedItem);
            setScannedItem(null);
            showNotification('Item added to cart successfully!', 'success');
          }}
          onCancel={() => {
            setScannedItem(null);
            showNotification('Item not added to cart.', 'info');
          }}
        />
      )}
    </div>
  );
};

export default Scanner;
