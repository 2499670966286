import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Plus, Trash2, Edit2, Save, X, Upload, Download, Eye, EyeOff } from 'lucide-react';
import { supabase } from '../supabaseClient';
import Navigation from './Navigation';
import ReactECharts from 'echarts-for-react';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { showNotification, showError } from '../utils/notificationHandler';

const ORDER_STATUSES = [
  'Pending',
  'Complete',
  'In Progress',
  'Cancelled',
  'On Hold',
  'On Order',
];

const UserSettings = () => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    role: '',
    companyName: '',
    mhsAccountNumber: '',
  });
  const [profileImage, setProfileImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [promos, setPromos] = useState([]);
  const [users, setUsers] = useState([]);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [editingAccount, setEditingAccount] = useState(null);
  const [changingPassword, setChangingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [changingEmail, setChangingEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  // New state for address handling
  const [addressInput, setAddressInput] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [structuredAddress, setStructuredAddress] = useState(null);
  const [editingAddressId, setEditingAddressId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initializeAutocomplete;
      document.body.appendChild(script);
    };
  
    const initializeAutocomplete = () => {
      const input = document.getElementById("new-address");
      if (input) {
        const autocomplete = new window.google.maps.places.Autocomplete(input, {
          types: ['address'],
          componentRestrictions: { country: "au" }
        });
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (!place.geometry) {
            console.log("Returned place contains no geometry");
            return;
          }
          const addressComponents = place.address_components;
          const formattedAddress = {
            address_line1: '',
            city: '',
            state: '',
            postal_code: '',
          };
    
          for (let component of addressComponents) {
            const componentType = component.types[0];
            switch (componentType) {
              case "street_number":
                formattedAddress.address_line1 = `${component.long_name} ${formattedAddress.address_line1}`;
                break;
              case "route":
                formattedAddress.address_line1 += component.short_name;
                break;
              case "postal_code":
                formattedAddress.postal_code = component.long_name;
                break;
              case "locality":
                formattedAddress.city = component.long_name;
                break;
              case "administrative_area_level_1":
                formattedAddress.state = component.short_name;
                break;
            }
          }
          setSelectedAddress(place.formatted_address);
          setAddressInput(place.formatted_address);
          setStructuredAddress(formattedAddress);
        });
      }
    };
  
    if (!window.google) {
      loadGoogleMapsScript();
    } else {
      initializeAutocomplete();
    }
  }, []);

  useEffect(() => {
    if (isAdmin) {
      fetchPromos();
      fetchUsers();
      fetchAccounts();
    }
  }, [isAdmin]);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
  
      if (user) {
        const { data, error } = await supabase
          .from('accounts')
          .select('*')
          .eq('id', user.id)
          .single();
  
        if (error) throw error;
  
        setFormData({
          fullName: data.full_name || '',
          email: data.email || '',
          role: data.role || '',
          companyName: data.company_name || '',
          mhsAccountNumber: data.mhs_account_number || '',
        });
  
        setIsAdmin(data.is_admin || false);
  
        if (data.avatar_url) {
          setProfileImage(data.avatar_url);
        }
  
        if (!data.is_admin) {
          fetchDeliveryAddresses(user.id);
        }
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      showError(error, 'Failed to fetch user profile');
    } finally {
      setLoading(false);
    }
  };

  const fetchDeliveryAddresses = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('delivery_addresses')
        .select('*')
        .eq('account_id', userId);

      if (error) throw error;

      console.log('Fetched addresses:', data);
      setDeliveryAddresses(data || []);
      if (data && data.length > 0) {
        setAddressInput(`${data[0].address_line1}, ${data[0].city}, ${data[0].state} ${data[0].postal_code}`);
      }
    } catch (error) {
      console.error('Error fetching delivery addresses:', error);
      showError(error, 'Failed to fetch delivery addresses');
    }
  };

  const fetchPromos = async () => {
    try {
      const { data, error } = await supabase
        .from('monthly_promos')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      setPromos(data);
    } catch (error) {
      console.error('Error fetching promos:', error);
      showError(error, 'Failed to fetch promos');
    }
  };

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('accounts')
        .select('id, email, full_name')
        .order('email', { ascending: true });

      if (error) throw error;

      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      showError(error, 'Failed to fetch users');
    }
  };

  const fetchAccounts = async () => {
    try {
      const { data, error } = await supabase
        .from('accounts')
        .select('id, full_name, company_name, mhs_account_number, discount_tier, can_view_full_stock')
        .order('full_name', { ascending: true });
  
      if (error) throw error;
  
      setAccounts(data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      showError(error, 'Failed to fetch accounts');
    }
  };

  const handleImageUpload = async (e) => {
    try {
      setUploading(true);
      const file = e.target.files[0];
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      
      if (!user) throw new Error('No user found');

      const fileExt = file.name.split('.').pop();
      const fileName = `${user.id}${Math.random()}.${fileExt}`;
      const filePath = `avatars/${fileName}`;

      let { error: uploadError } = await supabase.storage
        .from('main')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl }, error: urlError } = supabase.storage
        .from('main')
        .getPublicUrl(filePath);

      if (urlError) throw urlError;

      const { error: updateError } = await supabase
        .from('accounts')
        .update({ avatar_url: publicUrl })
        .eq('id', user.id);

      if (updateError) throw updateError;

      setProfileImage(publicUrl);
      showNotification('Profile image updated successfully!', 'success');
    } catch (error) {
      console.error('Error uploading image:', error);
      showError(error, 'Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  const handlePromoUpload = async (event) => {
    try {
      setUploading(true);
      const file = event.target.files[0];
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;

      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;

      let { error: uploadError } = await supabase.storage
        .from('promos')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl }, error: urlError } = supabase.storage
        .from('promos')
        .getPublicUrl(filePath);

      if (urlError) throw urlError;

      const { data, error: insertError } = await supabase
        .from('monthly_promos')
        .insert({
          file_name: file.name,
          file_path: filePath,
          public_url: publicUrl,
          uploaded_by: user.id
        })
        .select();

      if (insertError) throw insertError;

      showNotification('Promo PDF uploaded successfully!', 'success');
      fetchPromos();
    } catch (error) {
      console.error('Error uploading file:', error);
      showError(error, 'Failed to upload file');
    } finally {
      setUploading(false);
    }
  };

  const handleDeletePromo = async (id, filePath) => {
    try {
      const { error: deleteStorageError } = await supabase.storage
        .from('promos')
        .remove([filePath]);
  
      if (deleteStorageError) throw deleteStorageError;
  
      const { data: deleteData, error: deleteDbError } = await supabase
        .from('monthly_promos')
        .delete()
        .eq('id', id)
        .select();
  
      if (deleteDbError) throw deleteDbError;
      if (deleteData.length === 0) throw new Error('No promo was deleted. Check RLS policies.');
  
      showNotification('Promo deleted successfully', 'success');
      fetchPromos();
    } catch (error) {
      console.error('Error deleting promo:', error);
      showError(error, 'Failed to delete promo');
    }
  };

  const handleAssignPromo = async (promoId, userId) => {
    try {
      const { error } = await supabase
        .from('promo_assignments')
        .insert({ promo_id: promoId, user_id: userId });

      if (error) throw error;

      showNotification('Promo assigned successfully', 'success');
    } catch (error) {
      console.error('Error assigning promo:', error);
      showError(error, 'Failed to assign promo');
    }
  };

  const handleAddAddress = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      console.log('User:', user);
      console.log('Selected address:', selectedAddress);
      console.log('Structured address:', structuredAddress);
      
      if (!structuredAddress || !structuredAddress.postal_code) {
        throw new Error('Please select an address from the suggestions');
      }
      
      const { error } = await supabase
        .from('delivery_addresses')
        .insert({ 
          account_id: user.id, 
          address_line1: structuredAddress.address_line1,
          city: structuredAddress.city,
          state: structuredAddress.state,
          postal_code: structuredAddress.postal_code,
          country: 'Australia',
          is_primary: false
        });
  
      if (error) throw error;
  
      // Fetch the newly added address
      const { data: newAddressData, error: fetchError } = await supabase
        .from('delivery_addresses')
        .select('*')
        .eq('account_id', user.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .single();
  
      if (fetchError) throw fetchError;
  
      console.log('Added address:', newAddressData);
      setDeliveryAddresses([...deliveryAddresses, newAddressData]);
      setAddressInput('');
      setSelectedAddress('');
      setStructuredAddress(null);
      showNotification('Address added successfully', 'success');
    } catch (error) {
      console.error('Error adding address:', error);
      console.error('Error details:', error.message, error.details);
      showError(error, 'Failed to add address');
    }
  };

  const handleEditAddress = (id) => {
    setEditingAddressId(id);
    const addressToEdit = deliveryAddresses.find(addr => addr.id === id);
    setAddressInput(`${addressToEdit.address_line1}, ${addressToEdit.city}, ${addressToEdit.state} ${addressToEdit.postal_code}`);
    setSelectedAddress(`${addressToEdit.address_line1}, ${addressToEdit.city}, ${addressToEdit.state} ${addressToEdit.postal_code}`);
    setStructuredAddress({
      address_line1: addressToEdit.address_line1,
      city: addressToEdit.city,
      state: addressToEdit.state,
      postal_code: addressToEdit.postal_code
    });
  };
  
  const handleRemoveAddress = async (addressId) => {
    try {
      const { error } = await supabase
        .from('delivery_addresses')
        .delete()
        .eq('id', addressId);
  
      if (error) throw error;
  
      setDeliveryAddresses(deliveryAddresses.filter(addr => addr.id !== addressId));
      showNotification('Delivery address removed successfully!', 'success');
    } catch (error) {
      console.error('Error removing delivery address:', error);
      showError(error, 'Failed to remove delivery address');
    }
  };
  
  const handleSaveAddress = async () => {
    try {
      if (!structuredAddress || !structuredAddress.postal_code) {
        throw new Error('Please select an address from the suggestions');
      }
  
      const { error } = await supabase
        .from('delivery_addresses')
        .update({ 
          address_line1: structuredAddress.address_line1,
          city: structuredAddress.city,
          state: structuredAddress.state,
          postal_code: structuredAddress.postal_code
        })
        .eq('id', editingAddressId);
  
      if (error) throw error;
  
      setDeliveryAddresses(deliveryAddresses.map(addr =>
        addr.id === editingAddressId ? { ...addr, ...structuredAddress } : addr
      ));
      setEditingAddressId(null);
      setAddressInput('');
      setSelectedAddress('');
      setStructuredAddress(null);
      showNotification('Address updated successfully', 'success');
    } catch (error) {
      console.error('Error updating address:', error);
      showError(error, 'Failed to update address');
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      showError(new Error('Passwords do not match'), 'Passwords do not match');
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({ password: newPassword });
      if (error) throw error;
      showNotification('Password changed successfully', 'success');
      setChangingPassword(false);
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      showError(error, 'Failed to change password');
    }
  };

  const handleChangeEmail = async () => {
    try {
      const { error } = await supabase.auth.updateUser({ email: newEmail });
      if (error) throw error;
      showNotification('Email change request sent. Please check your new email for confirmation.', 'success');
      setChangingEmail(false);
      setNewEmail('');
    } catch (error) {
      console.error('Error changing email:', error);
      showError(error, 'Failed to change email');
    }
  };

  const handleEditAccount = (account) => {
    setEditingAccount(account);
  };

  const handleCancelEdit = () => {
    setEditingAccount(null);
    setAddressInput('');
  };

  const handleUpdateAccount = async (accountId, updates) => {
    try {
      const { data, error } = await supabase
        .from('accounts')
        .update(updates)
        .eq('id', accountId)
        .select();
  
      if (error) throw error;
  
      if (data && data.length > 0) {
        setEditingAccount(null);
        await fetchAccounts();
        showNotification('Account updated successfully', 'success');
      } else {
        throw new Error('No data returned from update operation');
      }
    } catch (error) {
      console.error('Error updating account:', error);
      showError(error, 'Failed to update account');
    }
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      showNotification('Successfully signed out!', 'success');
      history.push('/login');
    } catch (error) {
      showError(error, 'Failed to sign out');
    }
  };

  if (loading) {
    return <div className="text-center text-[#fed402]">Loading...</div>;
  }

  const discountTierOptions = [
    { display: 'DEF', value: 'def' },
    { display: 'R1', value: 'r1' },
    { display: 'R2', value: 'r2' },
    { display: 'R3', value: 'r3' },
    { display: 'R4', value: 'r4' },
    { display: 'R5', value: 'r5' },
    { display: 'R6', value: 'r6' },
  ];

  return (
    <div className="min-h-screen bg-[#1a1818]">
      <Navigation onSignOut={handleSignOut} />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-extrabold text-[#fed402] text-center mb-12"
        >
          {isAdmin ? 'Admin Settings' : 'User Settings'}
        </motion.h1>
        
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <h2 className="text-2xl font-bold text-[#1a1818] mb-4">Account Information</h2>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Full Name</label>
            <p className="text-gray-600">{formData.fullName}</p>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
            <p className="text-gray-600">{formData.email}</p>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Role</label>
            <p className="text-gray-600">{formData.role}</p>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Company Name</label>
            <p className="text-gray-600">{formData.companyName}</p>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">MHS Account Number</label>
            <p className="text-gray-600">{formData.mhsAccountNumber}</p>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profileImage">
              Profile Image
            </label>
            <div className="flex items-center">
              {profileImage && (
                <img src={profileImage} alt="Profile" className="w-16 h-16 rounded-full mr-4 object-cover" />
              )}
              <label
                htmlFor="file-upload"
                className="cursor-pointer bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded inline-flex items-center"
              >
                <Upload className="mr-2" />
                <span>{uploading ? 'Uploading...' : 'Select a file'}</span>
                <input 
                  id="file-upload"
                  type='file' 
                  className="hidden" 
                  onChange={handleImageUpload}
                  disabled={uploading}
                />
              </label>
            </div>
          </div>
        </motion.div>

        {!isAdmin && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <h2 className="text-2xl font-bold text-[#1a1818] mb-4">Delivery Addresses</h2>
            {deliveryAddresses.map((address) => (
              <div key={address.id} className="mb-4 p-4 border rounded flex justify-between items-center">
                <p>{`${address.address_line1}, ${address.city}, ${address.state} ${address.postal_code}`}</p>
                <div>
                  <button
                    onClick={() => handleEditAddress(address.id)}
                    className="text-blue-500 hover:text-blue-700 mr-2"
                  >
                    <Edit2 size={20} />
                  </button>
                  <button
                    onClick={() => handleRemoveAddress(address.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            ))}
            <div className="mt-4">
              <input
                id="new-address"
                type="text"
                value={addressInput}
                onChange={(e) => setAddressInput(e.target.value)}
                placeholder="Enter new address"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {editingAddressId ? (
                <div className="mt-2">
                  <button
                    onClick={handleSaveAddress}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                  >
                    <Save size={20} className="inline-block mr-2" />
                    Save
                  </button>
                  <button
                    onClick={handleCancelEdit}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    <X size={20} className="inline-block mr-2" />
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleAddAddress}
                  className="mt-2 bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  <Plus size={20} className="inline-block mr-2" />
                  Add Address
                </button>
              )}
            </div>
          </motion.div>
        )}

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <h2 className="text-2xl font-bold text-[#1a1818] mb-4">Change Password</h2>
          {changingPassword ? (
            <div>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              />
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm New Password"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              />
              <button
                onClick={handleChangePassword}
                className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              >
                Change Password
              </button>
              <button
                onClick={() => {
                  setChangingPassword(false);
                  setNewPassword('');
                  setConfirmPassword('');
                }}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={() => setChangingPassword(true)}
              className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Change Password
            </button>
          )}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <h2 className="text-2xl font-bold text-[#1a1818] mb-4">Change Email</h2>
          {changingEmail ? (
            <div>
              <input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="New Email"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              />
              <button
                onClick={handleChangeEmail}
                className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              >
                Change Email
              </button>
              <button
                onClick={() => {
                  setChangingEmail(false);
                  setNewEmail('');
                }}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={() => setChangingEmail(true)}
              className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Change Email
            </button>
          )}
        </motion.div>

        {isAdmin && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <h2 className="text-2xl font-bold text-[#1a1818] mb-4">Admin Area</h2>
            
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Upload New Promo</h3>
              <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-[#fed402] rounded-lg shadow-lg tracking-wide uppercase border border-[#fed402] cursor-pointer hover:bg-[#fed402] hover:text-white">
                <Upload className="w-8 h-8" />
                <span className="mt-2 text-base leading-normal">
                  {uploading ? 'Uploading...' : 'Select a PDF file'}
                </span>
                <input 
                  type='file' 
                  className="hidden" 
                  onChange={handlePromoUpload}
                  disabled={uploading}
                  accept=".pdf"
                />
              </label>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Existing Promos</h3>
              <ul className="space-y-2">
                {promos.map((promo) => (
                  <li key={promo.id} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                    <span>{promo.file_name}</span>
                    <div>
                      <select
                        onChange={(e) => handleAssignPromo(promo.id, e.target.value)}
                        className="mr-2 p-1 border rounded"
                      >
                        <option value="">Assign to user</option>
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.email} - {user.full_name}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={() => handleDeletePromo(promo.id, promo.file_path)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">User Accounts</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User Name</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company Name</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">MHS Account Number</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Discount Tier</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Full Stock View</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {accounts.map((account) => (
                      <tr key={account.id}>
                        <td className="px-6 py-4 whitespace-nowrap">{account.full_name}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{account.company_name}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{account.mhs_account_number}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {editingAccount && editingAccount.id === account.id ? (
                            <select
                              value={editingAccount.discount_tier}
                              onChange={(e) => setEditingAccount({...editingAccount, discount_tier: e.target.value})}
                              className="p-1 border rounded"
                            >
                              {discountTierOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.display}
                                </option>
                              ))}
                            </select>
                          ) : (
                            discountTierOptions.find(option => option.value === account.discount_tier)?.display || account.discount_tier
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {editingAccount && editingAccount.id === account.id ? (
                            <input
                              type="checkbox"
                              checked={editingAccount.can_view_full_stock}
                              onChange={(e) => setEditingAccount({...editingAccount, can_view_full_stock: e.target.checked})}
                              className="form-checkbox h-5 w-5 text-[#fed402]"
                            />
                          ) : (
                            account.can_view_full_stock ? (
                              <Eye className="text-green-500" size={20} />
                            ) : (
                              <EyeOff className="text-red-500" size={20} />
                            )
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {editingAccount && editingAccount.id === account.id ? (
                            <>
                              <button
                                onClick={() => handleUpdateAccount(account.id, {
                                  discount_tier: editingAccount.discount_tier,
                                  can_view_full_stock: editingAccount.can_view_full_stock
                                })}
                                className="text-green-500 hover:text-green-700 mr-2"
                              >
                                <Save size={20} />
                              </button>
                              <button
                                onClick={handleCancelEdit}
                                className="text-red-500 hover:text-red-700"
                              >
                                <X size={20} />
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => handleEditAccount(account)}
                              className="text-blue-500 hover:text-blue-700"
                            >
                              <Edit2 size={20} />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </motion.div>
        )}
        
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-8"
        >
          <button
            onClick={() => history.push('/dashboard')}
            className="w-full flex items-center justify-center py-3 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default UserSettings;