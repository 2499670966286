import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { toast } from 'react-toastify';

const Account = () => {
  const [monthlySpend, setMonthlySpend] = useState([]);
  const [accountDetails, setAccountDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetchAccountDetails();
  }, []);

  const fetchAccountDetails = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const response = await fetch('/api/monthly-spend/', {
          headers: {
            'Authorization': `Bearer ${user.access_token}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch account details');
        }

        const data = await response.json();
        setAccountDetails(data.debtor_info);
        setMonthlySpend(data.monthly_spend);
      }
    } catch (error) {
      console.error('Error fetching account details:', error);
      toast.error('Failed to fetch account details');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#1a1818]">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-extrabold text-[#fed402] text-center mb-12"
        >
          Account Overview
        </motion.h1>

        {loading ? (
          <p className="text-center text-[#fed402]">Loading...</p>
        ) : (
          <>
            {accountDetails && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-white rounded-lg shadow-md p-6 mb-8"
              >
                <h2 className="text-2xl font-bold text-[#1a1818] mb-4">Account Details</h2>
                <p><strong>Name:</strong> {accountDetails.name}</p>
                <p><strong>Abbreviation:</strong> {accountDetails.abbrev}</p>
                <p><strong>Phone:</strong> {accountDetails.phone_main}</p>
                <p><strong>Email:</strong> {accountDetails.email_main}</p>
                <p><strong>Address:</strong> {accountDetails.address1}, {accountDetails.address2}, {accountDetails.city}, {accountDetails.post_code}, {accountDetails.country}</p>
                <p><strong>Credit Limit:</strong> ${accountDetails.credit_limit}</p>
                <p><strong>Last Purchase:</strong> ${accountDetails.last_purch_amt} on {new Date(accountDetails.last_purch_date).toLocaleDateString()}</p>
                <p><strong>Last Payment:</strong> ${accountDetails.last_pay_amt} on {new Date(accountDetails.last_pay_date).toLocaleDateString()}</p>
              </motion.div>
            )}

            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white rounded-lg shadow-md p-6 mb-8"
            >
              <h2 className="text-2xl font-bold text-[#1a1818] mb-4">Monthly Spend</h2>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={monthlySpend}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="spend" fill="#fed402" />
                </BarChart>
              </ResponsiveContainer>
            </motion.div>
          </>
        )}

        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-8"
        >
          <button
            onClick={() => history.push('/dashboard')}
            className="w-full flex items-center justify-center py-3 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Account;