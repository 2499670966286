import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Navigation from './Navigation';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const StauffCatalogues = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const catalogues = [
    {
      id: 1,
      title: 'STAUFF Clamps',
      description: 'View online, download or request product catalogue STAUFF Clamps. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/clamps.jpg`,
      linkUrl: '/stauff-clamps',
    },
    {
      id: 2,
      title: 'STAUFF Connect',
      description: 'View online, download or request product catalogue STAUFF Connect. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/connect.jpg`,
      linkUrl: '/stauff-connect',
    },
    {
      id: 3,
      title: 'STAUFF Tube',
      description: 'View online and download product catalogue STAUFF Tube. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/tube.jpg`,
      linkUrl: '/stauff-tube',
    },
    {
      id: 4,
      title: 'STAUFF Flanges',
      description: 'View online, download or request product catalogue STAUFF Flanges. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/flanges.jpg`,
      linkUrl: '/stauff-flanges',
    },
    {
      id: 5,
      title: 'STAUFF Hose Connectors',
      description: 'View online, download or request product catalogue STAUFF Hose Connectors. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/hose_connectors.jpg`,
      linkUrl: '/stauff-hose-connectors',
    },
    {
      id: 6,
      title: 'STAUFF Quick Release Couplings',
      description: 'View online, download or request product catalogue STAUFF Quick Release Couplings. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/quick_release_couplings.jpg`,
      linkUrl: '/stauff-quick-release-couplings',
    },
    {
      id: 7,
      title: 'STAUFF Valves',
      description: 'View online, download or request product catalogue STAUFF Valves. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/valves.jpg`,
      linkUrl: '/stauff-valves',
    },
    {
      id: 8,
      title: 'STAUFF Test',
      description: 'View online, download or request product catalogue STAUFF Test. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/test.jpg`,
      linkUrl: '/stauff-test',
    },
    {
      id: 9,
      title: 'STAUFF Diagtronics',
      description: 'View online, download or request product catalogue STAUFF Diagtronics. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/diagtronics.jpg`,
      linkUrl: '/stauff-diagtronics',
    },
    {
      id: 10,
      title: 'STAUFF Filtration Technology',
      description: 'View online, download or request product catalogue STAUFF Filtration Technology. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/filtration_technology.jpg`,
      linkUrl: '/stauff-filtration-technology',
    },
    {
      id: 11,
      title: 'STAUFF Hydraulic Accessories',
      description: 'View online, download or request product catalogue STAUFF Hydraulic Accessories. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/hydraulic_accessories.jpg`,
      linkUrl: '/stauff-hydraulic-accessories',
    },
    {
      id: 12,
      title: 'STAUFF Accumulators',
      description: 'View online, download or request product catalogue STAUFF Accumulators. Learn more',
      imageUrl: `${process.env.PUBLIC_URL}/resources/catalogue_images/accumulators.jpg`,
      linkUrl: '/stauff-accumulators',
    },
  ];

  return (
    <div className="min-h-screen bg-[#1a1818] text-[#fed402]">
      <Navigation />

      <div className="px-4 py-6 md:p-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-2xl md:text-4xl font-extrabold text-center mb-6 md:mb-12"
        >
          STAUFF Product Catalogues
        </motion.h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          {catalogues.map((catalogue) => (
            <CatalogueTile 
              key={catalogue.id}
              title={catalogue.title}
              description={catalogue.description}
              imageUrl={catalogue.imageUrl}
              linkUrl={catalogue.linkUrl}
              isMobile={isMobile}
            />
          ))}
        </div>

        <div className="mt-8 md:mt-12 text-center">
          <button
            onClick={() => history.push('/product-documents')}
            className="w-full md:w-auto bg-[#fed402] text-[#1a1818] px-4 py-2 rounded-full font-semibold hover:bg-[#e0bc02] transition duration-200 flex items-center justify-center"
          >
            <ArrowLeft className="mr-2" size={isMobile ? 16 : 20} />
            Back to Product Documents
          </button>
        </div>
      </div>
    </div>
  );
};

const CatalogueTile = ({ title, description, imageUrl, linkUrl, isMobile }) => {
  const history = useHistory();

  return (
    <motion.div
      whileHover={isMobile ? {} : { scale: 1.05 }}
      className="bg-[#fed402] rounded-xl shadow-xl overflow-hidden cursor-pointer"
      onClick={() => linkUrl && history.push(linkUrl)}
    >
      <img src={imageUrl} alt={title} className="w-full h-32 md:h-48 object-cover" />
      <div className="p-4 md:p-6">
        <h3 className="text-base md:text-lg font-bold text-[#1a1818] mb-1 md:mb-2">{title}</h3>
        <p className="text-xs md:text-sm text-[#1a1818] opacity-80">{description}</p>
      </div>
    </motion.div>
  );
};

export default StauffCatalogues;