import React, { useState, useEffect, useCallback } from 'react';
import { Bell, BellOff } from 'lucide-react';
import { supabase } from '../supabaseClient';
import { toast } from 'react-toastify';
import { showNotification, showError } from '../utils/notificationHandler';

const NotifyMeButton = ({ partNumber, isInStock }) => {
  const [isWatching, setIsWatching] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkIfWatching = useCallback(async () => {
    if (!partNumber) return;

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('stock_notifications')
          .select('*')
          .eq('user_id', user.id)
          .eq('part_number', partNumber)
          .single();

        if (error && error.code !== 'PGRST116') {
          console.error('Error checking notifications:', error);
        } else {
          setIsWatching(!!data);
        }
      }
    } catch (error) {
      console.error('Error checking if watching:', error);
    }
  }, [partNumber]);

  useEffect(() => {
    checkIfWatching();
  }, [checkIfWatching]);

  const handleNotifyMe = async () => {
    if (!partNumber) {
      console.error("partNumber is undefined or null");
      showNotification('Invalid part number. Please check and try again.', 'error');
      return;
    }

    setLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        if (isWatching) {
          const { error } = await supabase
            .from('stock_notifications')
            .delete()
            .eq('user_id', user.id)
            .eq('part_number', partNumber);

          if (error) throw error;
          setIsWatching(false);
          showNotification('Notification removed successfully', 'success');
        } else {
          const { error } = await supabase
            .from('stock_notifications')
            .insert({ user_id: user.id, part_number: partNumber });

          if (error) throw error;
          setIsWatching(true);
          showNotification('You will be notified when this item is back in stock', 'success');
        }
      } else {
        showNotification('You must be logged in to set notifications', 'error');
      }
    } catch (error) {
      console.error('Error handling notification:', error);
      showError(error, 'Failed to update notification. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (isInStock) {
    return null;
  }

  return (
    <button
      onClick={handleNotifyMe}
      disabled={loading}
      className={`flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white ${
        isWatching ? 'bg-green-600 hover:bg-green-700' : 'bg-blue-600 hover:bg-blue-700'
      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
    >
      {isWatching ? (
        <>
          <BellOff className="mr-2" size={16} />
          Stop Notifying
        </>
      ) : (
        <>
          <Bell className="mr-2" size={16} />
          Notify Me
        </>
      )}
    </button>
  );
};

export default NotifyMeButton;