import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Clock } from 'lucide-react';

const CutoffCountdown = () => {
  const [timeRemaining, setTimeRemaining] = useState('');
  const [isVisible, setIsVisible] = useState(localStorage.getItem('showCountdown') !== 'false');

  const calculateTimeRemaining = () => {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const hours = now.getHours();

    let targetDate = new Date(now);

    // If it's weekend, set target to next Monday
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      targetDate.setDate(targetDate.getDate() + (8 - dayOfWeek) % 7);
      targetDate.setHours(11, 0, 0, 0);
    } 
    // If it's after 11 AM on a weekday, set target to next weekday
    else if (hours >= 11) {
      targetDate.setDate(targetDate.getDate() + 1);
      targetDate.setHours(11, 0, 0, 0);
    }
    // If it's before 11 AM on a weekday, set target to today at 11 AM
    else {
      targetDate.setHours(11, 0, 0, 0);
    }

    const timeDiff = targetDate - now;
    const hoursRemaining = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutesRemaining = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const secondsRemaining = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return `${hoursRemaining}h ${minutesRemaining}m ${secondsRemaining}s`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const remaining = calculateTimeRemaining();
      setTimeRemaining(remaining);

      // Show toast notification at certain thresholds
      if (remaining === '1h 0m 0s' || remaining === '0h 30m 0s' || remaining === '0h 15m 0s') {
        toast.info(`Airbag Order cutoff in ${remaining}!`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleDismiss = () => {
    setIsVisible(false);
    localStorage.setItem('showCountdown', 'false');
    toast.info("Cutoff countdown dismissed. You can reopen it from the user menu.", {
      position: "top-right",
      autoClose: 5000,
    });
  };

  if (!isVisible) return null;

  return (
    <div className="flex items-center bg-[#fed402] text-[#1a1818] px-3 py-1 rounded-full text-sm font-medium">
      <Clock className="w-4 h-4 mr-2" />
      <span>Airbag Order Cutoff: {timeRemaining}</span>
      <button onClick={handleDismiss} className="ml-2 text-[#1a1818] hover:text-[#1a1818] focus:outline-none">
        ✕
      </button>
    </div>
  );
};

export default CutoffCountdown;