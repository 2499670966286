import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, ShoppingCart, ClipboardList, Upload, Star, FileText, ChevronLeft, ChevronRight, Activity, Database, QrCode, Tag } from 'lucide-react';
import { supabase } from '../supabaseClient';
import Navigation from './Navigation';
import NotificationTimeline from './NotificationTimeline';
import CookieConsent from "react-cookie-consent";
import { showNotification, showError } from '../utils/notificationHandler';

const Dashboard = () => {
  const history = useHistory();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [currentBrandIndex, setCurrentBrandIndex] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  // Brand logos from the specified directory
  const brandLogos = [
    "/resources/assets/brand8.png",
    "/resources/assets/brand5.png",
    "/resources/assets/brand3.png",
    "/resources/assets/brand4.png",
    "/resources/assets/brand2.png",
    "/resources/assets/brand6.png",
    "/resources/assets/brand7.png",
    "/resources/assets/brand1.png",
  ];

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        fetchUserData();
        fetchUnreadNotificationsCount();
      }
    };
    checkAuth();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Automatic carousel
    const carouselInterval = setInterval(() => {
      if (!isMobile) {
        setCurrentBrandIndex((prevIndex) =>
          prevIndex === brandLogos.length - 1 ? 0 : prevIndex + 1
        );
      }
    }, 3000); // Change image every 3 seconds

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(carouselInterval);
    };
  }, [isMobile, brandLogos.length]);

  useEffect(() => {
    const loadTidioScript = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const script = document.createElement('script');
        script.src = "//code.tidio.co/pytxxbcriheuka2so6gx5wqab4ljwurp.js";
        script.async = true;
        document.body.appendChild(script);

        // Position Tidio chat on the left side
        const styleElement = document.createElement('style');
        styleElement.textContent = `
          #tidio-chat iframe { left: 20px !important; right: auto !important; }
        `;
        document.head.appendChild(styleElement);

        return () => {
          document.body.removeChild(script);
          document.head.removeChild(styleElement);
        };
      }
    };
    loadTidioScript();
  }, []);

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { data, error } = await supabase
        .from('accounts')
        .select('is_admin, is_staff, company_name')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      setIsAdmin(data.is_admin);
      setIsStaff(data.is_staff);
      setCompanyName(data.company_name);
    } catch (error) {
      console.error('Error fetching user data:', error);
      showError(error, 'Failed to load dashboard data. Please refresh the page.');
    }
  };

  const fetchUnreadNotificationsCount = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { count, error } = await supabase
          .from('notifications')
          .select('*', { count: 'exact', head: true })
          .eq('user_id', user.id)
          .eq('read', false);

        if (error) throw error;
        setUnreadNotificationsCount(count);
      }
    } catch (error) {
      console.error('Error fetching unread notifications count:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      showNotification('Successfully signed out!', 'success');
      history.push('/login');
    } catch (error) {
      showError(error, 'Failed to sign out. Please try again.');
    }
  };

  const nextBrand = () => {
    setCurrentBrandIndex((prevIndex) =>
      prevIndex === brandLogos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevBrand = () => {
    setCurrentBrandIndex((prevIndex) =>
      prevIndex === 0 ? brandLogos.length - 1 : prevIndex - 1
    );
  };

  const DashboardCard = ({ icon, title, description, onClick }) => (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className="bg-[#fed402] rounded-xl shadow-xl overflow-hidden cursor-pointer"
      onClick={onClick}
    >
      <div className="p-4 md:p-6">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            {icon}
          </div>
          <div className="ml-4">
            <h3 className="text-base md:text-lg font-medium text-[#1a1818]">{title}</h3>
            <p className="mt-1 text-sm text-[#1a1818] opacity-80">{description}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );

  const dashboardCards = [
    {
      icon: <Search className="h-6 w-6 md:h-8 md:w-8 text-[#1a1818]" />,
      title: "Part Enquiry",
      description: "Search for part information",
      onClick: () => history.push('/part-enquiry'),
    },
    {
      icon: <ShoppingCart className="h-6 w-6 md:h-8 md:w-8 text-[#1a1818]" />,
      title: "Quote Cart",
      description: "View your quote cart",
      onClick: () => history.push('/cart'),
    },
    {
      icon: <ClipboardList className="h-6 w-6 md:h-8 md:w-8 text-[#1a1818]" />,
      title: "Recent Orders",
      description: "View your recent orders",
      onClick: () => history.push('/recent-orders'),
    },
    {
      icon: <Upload className="h-6 w-6 md:h-8 md:w-8 text-[#1a1818]" />,
      title: "Bulk Upload",
      description: "Upload and process bulk orders",
      onClick: () => history.push('/bulk-upload'),
    },
    {
      icon: <FileText className="h-6 w-6 md:h-8 md:w-8 text-[#1a1818]" />,
      title: "Product Documents",
      description: "View and download product documents",
      onClick: () => history.push('/product-documents'),
    },
    {
      icon: <Star className="h-6 w-6 md:h-8 md:w-8 text-[#1a1818]" />,
      title: "SLSP Promo",
      description: "View and download the latest SLSP promotions",
      onClick: () => history.push('/slsp-promo'),
    },
    {
      icon: <Database className="h-6 w-6 md:h-8 md:w-8 text-[#1a1818]" />,
      title: "Part Number Management",
      description: "Manage and organize part numbers",
      onClick: () => history.push('/part-number-management'),
    },
    {
      icon: <Tag className="h-6 w-6 md:h-8 md:w-8 text-[#1a1818]" />,
      title: "Part Label Management",
      description: "Manage and print part labels",
      onClick: () => history.push('/part-label-management'),
    },
  ];

  // Add Scanner card only for mobile view
  if (isMobile) {
    dashboardCards.push({
      icon: <QrCode className="h-6 w-6 md:h-8 md:w-8 text-[#1a1818]" />,
      title: "Scanner",
      description: "Scan QR codes or barcodes",
      onClick: () => history.push('/scanner'),
    });
  }

  // Add Staff Area card for staff users
  if (isStaff || isAdmin) {
    dashboardCards.push({
      icon: <ClipboardList className="h-6 w-6 md:h-8 md:w-8 text-[#1a1818]" />,
      title: "Staff Area",
      description: "Manage orders and view analytics",
      onClick: () => history.push('/staff-area'),
    });
  }

  return (
    <div className="min-h-screen bg-[#1a1818] relative flex flex-col">
      <Navigation 
        onSignOut={handleSignOut} 
        isAdmin={isAdmin} 
        unreadNotificationsCount={unreadNotificationsCount}
        onNotificationClick={() => setShowNotifications(!showNotifications)}
      />
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex-grow">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-8 md:mb-16 flex flex-col md:flex-row justify-center items-center md:items-baseline"
        >
          <span className="text-xl md:text-2xl font-bold text-[#fed402] mr-2">Welcome,</span>
          <span className="text-xl md:text-2xl font-extrabold text-[#fed402]">{companyName}</span>
        </motion.h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mb-8 md:mb-12">
          {dashboardCards.map((card, index) => (
            <DashboardCard
              key={index}
              icon={card.icon}
              title={card.title}
              description={card.description}
              onClick={card.onClick}
            />
          ))}
        </div>
        
        {/* Brands Carousel - Hidden on mobile */}
        {!isMobile && (
          <div className="mt-8 md:mt-16 mb-8 relative">
            <h2 className="text-xl md:text-2xl font-bold text-[#fed402] mb-4 text-center">Our Brands</h2>
            <div className="flex justify-center items-center">
              <button onClick={prevBrand} className="p-2 text-[#fed402]">
                <ChevronLeft size={24} />
              </button>
              <motion.div
                key={currentBrandIndex}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="w-32 md:w-48 h-16 md:h-24 flex justify-center items-center"
              >
                <img
                  src={brandLogos[currentBrandIndex]}
                  alt={`Brand ${currentBrandIndex + 1}`}
                  className="max-w-full max-h-full object-contain"
                />
              </motion.div>
              <button onClick={nextBrand} className="p-2 text-[#fed402]">
                <ChevronRight size={24} />
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Footer */}
      <footer className="bg-[#1a1818] text-[#fed402] py-4 px-4 md:px-0">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <p className="text-xs md:text-sm">
              © 2024 MHS | Mining and Hydraulic Supplies ABN 29 559 250 199
            </p>
            <div className="text-xs md:text-sm">
              <a href="https://miningandhydraulics.com.au/privacy-policy/" target="_blank" rel="noopener noreferrer" className="hover:underline">
                Privacy Policy
              </a>{' | '}
              <a href="https://miningandhydraulics.com.au/terms-conditions/" target="_blank" rel="noopener noreferrer" className="hover:underline">
                Terms & Conditions
              </a>
            </div>
          </div>
          <div className="text-xs md:text-sm text-center md:text-right">
            System designed with <span className="text-red-600">❤️</span> by Squid - Beta v0.9.1
          </div>
        </div>
      </footer>

      {/* System Status Icon */}
      <div className="fixed bottom-4 right-4 z-50">
        <a
          href="https://status.mhsonline.au/status/partnerportal"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center bg-[#fed402] text-[#1a1818] px-3 py-2 rounded-full hover:bg-[#e0bc02] transition-colors shadow-lg text-sm"
        >
          <Activity className="mr-2" size={16} />
          <span className="font-medium">System Status</span>
        </a>
      </div>

      {/* Cookie Consent Popup */}
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "10px" }}>By continuing to use this site, you accept our use of cookies.</span>
      </CookieConsent>

      <AnimatePresence>
        {showNotifications && (
          <NotificationTimeline 
            onClose={() => setShowNotifications(false)} 
            updateUnreadCount={fetchUnreadNotificationsCount}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Dashboard;