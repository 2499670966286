import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Check } from 'lucide-react';
import { supabase } from '../supabaseClient';
import Navigation from './Navigation';
import { showNotification, showError } from '../utils/notificationHandler';

const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [allowPartialDelivery, setAllowPartialDelivery] = useState(true);
  const [orderInstructions, setOrderInstructions] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchDeliveryAddresses();
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      setCartItems(JSON.parse(storedCart));
    }
  }, []);

  const fetchDeliveryAddresses = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('delivery_addresses')
          .select('*')
          .eq('account_id', user.id)
          .order('is_primary', { ascending: false });

        if (error) throw error;
        setDeliveryAddresses(data);
        if (data.length > 0) {
          setSelectedAddressId(data[0].id);
        }
      }
    } catch (error) {
      console.error('Error fetching delivery addresses:', error);
      showError(error, 'Failed to fetch delivery addresses');
    }
  };

  const generateXML = () => {
    const currentDate = new Date().toISOString().split('T')[0];
    const selectedAddress = deliveryAddresses.find(addr => addr.id === selectedAddressId);

    let xml = `<?xml version="1.0" ?>
<MoPro>
  <Order>
    <OrderDetail>
      <OrderMode>N</OrderMode>
      <OrderNumber>${orderNumber}</OrderNumber>
      <OrderType>E</OrderType>
      <OrderDate>${currentDate}</OrderDate>
      <OrderShipDate>${currentDate}</OrderShipDate>
      <OrderExpiryDate></OrderExpiryDate>
      <OrderInstructions1>${orderInstructions.slice(0, 100)}</OrderInstructions1>
      <OrderInstructions2>${orderInstructions.slice(100, 200)}</OrderInstructions2>
      <AccountNumber>${selectedAddress.address_line1}</AccountNumber>
      <EntityCode>MHS</EntityCode>
      <QuoteNumber></QuoteNumber>
      <PriceScheduleCode></PriceScheduleCode>
      <AllowPartialDelivery>${allowPartialDelivery ? 'yes' : 'no'}</AllowPartialDelivery>
      <InvoicePerDespatch>no</InvoicePerDespatch>
      <NumberOfLines>${cartItems.length}</NumberOfLines>
      <Comment></Comment>
      <UserDefined>
        <Field1></Field1>
        <Field2></Field2>
        <Field3></Field3>
        <Field4></Field4>
        <Field5></Field5>
        <Field6></Field6>
      </UserDefined>
    </OrderDetail>
    <Delivery>
      <SiteCode></SiteCode>
      <Name></Name>
      <Address1>${selectedAddress.address_line1}</Address1>
      <Address2>${selectedAddress.address_line2 || ''}</Address2>
      <City>${selectedAddress.city}</City>
      <State>${selectedAddress.state}</State>
      <PostalCode>${selectedAddress.postal_code}</PostalCode>
      <DeliveryType></DeliveryType>
      <SalesPersonID></SalesPersonID>
      <Contact>
        <Name></Name>
        <Phone></Phone>
        <Fax></Fax>
        <Email></Email>
      </Contact>
      <Freight>
        <Carrier></Carrier>
        <SendVia></SendVia>
        <FreightAccount></FreightAccount>
        <ShippingInstructions></ShippingInstructions>
        <PackingInstructions></PackingInstructions>
      </Freight>
      ${cartItems.map((item, index) => `
      <OrderLine>
        <LineNumber>${index + 1}</LineNumber>
        <Barcode></Barcode>
        <SupplierProductCode>${item.partNumber}</SupplierProductCode>
        <CustomerProductCode></CustomerProductCode>
        <Description>${item.description}</Description>
        <UnitCode></UnitCode>
        <Quantity>${item.quantity}</Quantity>
        <Price>${item.price}</Price>
        <ShipDate></ShipDate>
        <ExpiryDate></ExpiryDate>
        <Comment>${item.comment || ''}</Comment>
        <PackSize>1</PackSize>
        <WarehouseCode>MHS</WarehouseCode>
      </OrderLine>`).join('')}
    </Delivery>
  </Order>
</MoPro>`;

    return xml;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');
  
      const xml = generateXML();
      
      // Store XML in Supabase Storage
      const fileName = `order_${Date.now()}.xml`;
      const { data, error: uploadError } = await supabase.storage
        .from('orders')
        .upload(fileName, xml, {
          contentType: 'application/xml',
          upsert: false
        });
  
      if (uploadError) throw uploadError;
  
      // Get the public URL of the uploaded file
      const { data: { publicUrl }, error: urlError } = supabase.storage
        .from('orders')
        .getPublicUrl(fileName);
  
      if (urlError) throw urlError;
  
      // Store order details in Supabase database
      const { error: orderError } = await supabase
        .from('orders')
        .insert([
          { 
            user_id: user.id,
            order_number: orderNumber,
            delivery_address_id: selectedAddressId,
            allow_partial_delivery: allowPartialDelivery,
            order_instructions: orderInstructions,
            cart_items: cartItems,
            xml_url: publicUrl
          }
        ]);
  
      if (orderError) {
        // Check if the error is due to a duplicate order number
        if (orderError.code === '23505' && orderError.message.includes('unique_order_number')) {
          // If it's a duplicate, we'll allow it and continue with the order
          console.log('Duplicate order number detected, but allowing it.');
        } else {
          throw orderError;
        }
      }
  
      showNotification('Order processed successfully', 'success');
      localStorage.removeItem('cart');
      history.push('/dashboard');
    } catch (error) {
      console.error('Error processing order:', error);
      showError(error, 'Failed to process your order. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      showNotification('Successfully signed out!', 'success');
      history.push('/login');
    } catch (error) {
      showError(error, 'Failed to sign out. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-[#1a1818]">
      <Navigation onSignOut={handleSignOut} />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-extrabold text-[#fed402] text-center mb-12"
        >
          Checkout
        </motion.h1>
        
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <h2 className="text-2xl font-bold text-[#1a1818] mb-4">Order Summary</h2>
          {cartItems.map((item, index) => (
            <div key={index} className="mb-4 p-4 border rounded">
              <p className="font-bold">{item.partNumber}</p>
              <p>{item.description}</p>
              <p>Quantity: {item.quantity}</p>
              <p>Price: ${parseFloat(item.price).toFixed(2)}</p>
              <textarea
                className="mt-2 w-full p-2 border rounded"
                placeholder="Add a comment for this item"
                value={item.comment || ''}
                onChange={(e) => {
                  const updatedItems = [...cartItems];
                  updatedItems[index].comment = e.target.value;
                  setCartItems(updatedItems);
                }}
              ></textarea>
            </div>
          ))}
          <p className="text-xl font-bold mt-4">
            Total: ${cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0).toFixed(2)}
          </p>
        </motion.div>
        
        <motion.form
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="orderNumber">
              Order Number
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="orderNumber"
              type="text"
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
              required
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deliveryAddress">
              Delivery Address
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="deliveryAddress"
              value={selectedAddressId}
              onChange={(e) => setSelectedAddressId(e.target.value)}
              required
            >
              {deliveryAddresses.map(address => (
                <option key={address.id} value={address.id}>
                  {address.address_line1}, {address.city}, {address.state} {address.postal_code}
                </option>
              ))}
            </select>
          </div>
          
          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={allowPartialDelivery}
                onChange={(e) => setAllowPartialDelivery(e.target.checked)}
                className="mr-2"
              />
              <span className="text-gray-700 text-sm font-bold">Allow Partial Delivery</span>
            </label>
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="orderInstructions">
              Order Instructions
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="orderInstructions"
              rows="4"
              value={orderInstructions}
              onChange={(e) => setOrderInstructions(e.target.value)}
            ></textarea>
          </div>
          
          <div className="flex items-center justify-between">
            <button
              className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </>
              ) : (
                <>
                  <Check className="mr-2" />
                  Place Order
                </>
              )}
            </button>
          </div>
        </motion.form>
        
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-8"
        >
          <button
            onClick={() => history.push('/cart')}
            className="w-full flex items-center justify-center py-3 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Cart
          </button>
        </motion.div>
        </div>
    </div>
  );
};

export default Checkout;