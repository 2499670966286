import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from './supabaseClient';

// Import components
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import PartEnquiry from './components/PartEnquiry';
import Cart from './components/Cart';
import RecentOrders from './components/RecentOrders';
import UserSettings from './components/UserSettings';
import Account from './components/Account';
import Checkout from './components/Checkout';
import BulkUpload from './components/BulkUpload';
import ProductDocuments from './components/ProductDocuments';
import StauffCatalogues from './components/StauffCatalogues';
import StauffClamps from './components/StauffClamps';
import SlspPromo from './components/SlspPromo';
import NotFound from './components/NotFound';
import Scanner from './components/Scanner';
import PartNumberManagement from './components/PartNumberManagement';
import StaffArea from './components/StaffArea';
import PartLabelManagement from './components/PartLabelManagement';

function App() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Switch>
          <Route exact path="/">
            {session ? <Redirect to="/dashboard" /> : <Login />}
          </Route>
          <Route exact path="/login">
            {session ? <Redirect to="/dashboard" /> : <Login />}
          </Route>
          <Route exact path="/dashboard">
            {!session ? <Redirect to="/" /> : <Dashboard />}
          </Route>
          <Route path="/part-enquiry">
            {!session ? <Redirect to="/" /> : <PartEnquiry />}
          </Route>
          <Route path="/cart">
            {!session ? <Redirect to="/" /> : <Cart />}
          </Route>
          <Route path="/recent-orders">
            {!session ? <Redirect to="/" /> : <RecentOrders />}
          </Route>
          <Route path="/user-settings">
            {!session ? <Redirect to="/" /> : <UserSettings />}
          </Route>
          <Route path="/account">
            {!session ? <Redirect to="/" /> : <Account />}
          </Route>
          <Route path="/checkout">
            {!session ? <Redirect to="/" /> : <Checkout />}
          </Route>
          <Route path="/bulk-upload">
            {!session ? <Redirect to="/" /> : <BulkUpload />}
          </Route>
          <Route path="/product-documents">
            {!session ? <Redirect to="/" /> : <ProductDocuments />}
          </Route>
          <Route path="/stauff-catalogues">
            {!session ? <Redirect to="/" /> : <StauffCatalogues />}
          </Route>
          <Route path="/stauff-clamps">
            {!session ? <Redirect to="/" /> : <StauffClamps />}
          </Route>
          <Route path="/slsp-promo">
            {!session ? <Redirect to="/" /> : <SlspPromo />}
          </Route>
          <Route exact path="/scanner">
            {!session ? <Redirect to="/" /> : <Scanner />}
          </Route>
          <Route path="/part-number-management">
            {!session ? <Redirect to="/" /> : <PartNumberManagement />}
          </Route>
          <Route path="/staff-area">
            {!session ? <Redirect to="/" /> : <StaffArea />}
          </Route>
          <Route path="/part-label-management">
            {!session ? <Redirect to="/" /> : <PartLabelManagement />}
          </Route>
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;