import React, { useState, useRef, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Mail, Lock, RefreshCw, ArrowLeft } from 'lucide-react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { showNotification, showError } from '../utils/notificationHandler';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [authMethod, setAuthMethod] = useState('otp');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const captchaRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const checkOtpSent = () => {
      const otpSentTime = localStorage.getItem('otpSentTime');
      if (otpSentTime) {
        const timeDiff = Date.now() - parseInt(otpSentTime);
        if (timeDiff < 5 * 60 * 1000) {
          setOtpSent(true);
          setEmail(localStorage.getItem('otpEmail') || '');
        } else {
          localStorage.removeItem('otpSentTime');
          localStorage.removeItem('otpEmail');
        }
      }
    };

    checkOtpSent();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (forgotPasswordMode) {
        await handleForgotPassword();
      } else if (authMethod === 'password') {
        if (!captchaToken) {
          showNotification('Please complete the captcha', 'error');
          setLoading(false);
          return;
        }
        const { data, error } = await supabase.auth.signInWithPassword({
          email,
          password,
          options: {
            captchaToken,
          },
        });
        if (error) throw error;
        handleSuccessfulLogin(data);
      } else if (authMethod === 'otp') {
        if (!otpSent) {
          if (!captchaToken) {
            showNotification('Please complete the captcha', 'error');
            setLoading(false);
            return;
          }
          await sendOTP();
        } else {
          await verifyOTP();
        }
      }
    } catch (error) {
      console.error('Login error:', error);
      showError(error, 'Login failed. Please check your credentials and try again.');
    } finally {
      setLoading(false);
      if (captchaRef.current) {
        captchaRef.current.resetCaptcha();
      }
      setCaptchaToken(null);
    }
  };

  const sendOTP = async () => {
    try {
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          shouldCreateUser: false,
          captchaToken,
        },
      });

      if (error) {
        console.error('Detailed OTP error:', error);
        throw error;
      }
      setOtpSent(true);
      localStorage.setItem('otpSentTime', Date.now().toString());
      localStorage.setItem('otpEmail', email);
      showNotification('OTP sent to your email. Please check your inbox.', 'success');
    } catch (error) {
      console.error('Caught error in sendOTP:', error);
      throw error;
    }
  };

  const verifyOTP = async () => {
    const { data, error } = await supabase.auth.verifyOtp({
      email,
      token: otp,
      type: 'magiclink',
    });

    if (error) throw error;
    handleSuccessfulLogin(data);
  };

  const handleSuccessfulLogin = async (data) => {
    if (data.user) {
      const { data: accountData, error: accountError } = await supabase
        .from('accounts')
        .select('id')
        .eq('id', data.user.id)
        .single();

      if (accountError) throw accountError;
      localStorage.setItem('accountId', accountData.id);
      
      localStorage.removeItem('otpSentTime');
      localStorage.removeItem('otpEmail');

      showNotification('Successfully logged in!', 'success');
      history.push('/dashboard');
    }
  };

  const handleForgotPassword = async () => {
    if (!captchaToken) {
      showNotification('Please complete the captcha', 'error');
      return;
    }

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
        captchaToken,
      });

      if (error) throw error;

      showNotification('Password reset instructions sent to your email.', 'success');
      setForgotPasswordMode(false);
    } catch (error) {
      console.error('Forgot password error:', error);
      showError(error, 'Failed to send password reset instructions. Please try again.');
    }
  };

  const toggleForgotPasswordMode = () => {
    setForgotPasswordMode(!forgotPasswordMode);
    setEmail('');
    setCaptchaToken(null);
    if (captchaRef.current) {
      captchaRef.current.resetCaptcha();
    }
  };

  const handleResendOTP = async () => {
    setCaptchaToken(null);
    setOtpSent(false);
    if (captchaRef.current) {
      captchaRef.current.resetCaptcha();
    }
    showNotification('Please complete the captcha to resend OTP', 'info');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#1a1818] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <img
            src="/Logo2.png"
            alt="Company Logo"
            className="mx-auto h-24 md:h-36 w-auto"
          />
          <h2 className="mt-6 text-center text-2xl md:text-3xl font-extrabold text-[#fed402]">
            {forgotPasswordMode ? 'Reset Your Password' : 'Sign in to your account'}
          </h2>
        </motion.div>
        <motion.form 
          className="mt-8 space-y-6" 
          onSubmit={handleLogin}
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">Email address</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-[#1a1818]" />
                </div>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 pl-10 border border-[#fed402] placeholder-gray-500 text-[#1a1818] rounded-t-md focus:outline-none focus:ring-[#fed402] focus:border-[#fed402] focus:z-10 sm:text-sm bg-white"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={otpSent && !forgotPasswordMode}
                />
              </div>
            </div>
            {!forgotPasswordMode && (
              authMethod === 'password' ? (
                <div>
                  <label htmlFor="password" className="sr-only">Password</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Lock className="h-5 w-5 text-[#1a1818]" />
                    </div>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 pl-10 border border-[#fed402] placeholder-gray-500 text-[#1a1818] rounded-b-md focus:outline-none focus:ring-[#fed402] focus:border-[#fed402] focus:z-10 sm:text-sm bg-white"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
              ) : otpSent ? (
                <div>
                  <label htmlFor="otp" className="sr-only">OTP</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Lock className="h-5 w-5 text-[#1a1818]" />
                    </div>
                    <input
                      id="otp"
                      name="otp"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 pl-10 border border-[#fed402] placeholder-gray-500 text-[#1a1818] rounded-b-md focus:outline-none focus:ring-[#fed402] focus:border-[#fed402] focus:z-10 sm:text-sm bg-white"
                      placeholder="Enter 6-digit OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                </div>
              ) : null
            )}
          </div>

          <div className="flex items-center justify-between">
            {!forgotPasswordMode && (
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => {
                    setAuthMethod(authMethod === 'otp' ? 'password' : 'otp');
                    setOtpSent(false);
                    setOtp('');
                  }}
                  className="font-medium text-[#fed402] hover:text-[#e0bc02] focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  {authMethod === 'otp' ? 'Use password instead' : 'Use OTP instead'}
                </button>
              </div>
            )}
            <div className="text-sm">
              <button
                type="button"
                onClick={toggleForgotPasswordMode}
                className="font-medium text-[#fed402] hover:text-[#e0bc02] focus:outline-none focus:underline transition ease-in-out duration-150 flex items-center"
              >
                {forgotPasswordMode ? (
                  <>
                    <ArrowLeft className="mr-1 h-4 w-4" />
                    Back to Login
                  </>
                ) : (
                  'Forgot your password?'
                )}
              </button>
            </div>
          </div>

          {(!otpSent || authMethod === 'password' || forgotPasswordMode) && (
            <div className="flex justify-center">
              <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                onVerify={(token) => setCaptchaToken(token)}
                ref={captchaRef}
                size={isMobile ? 'compact' : 'normal'}
              />
            </div>
          )}

          <div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-[#1a1818] bg-[#fed402] hover:bg-[#e0bc02] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
              disabled={loading || (!otpSent && !captchaToken) || (forgotPasswordMode && !captchaToken)}
            >
              {loading ? 'Processing...' : 
               forgotPasswordMode ? 'Send Reset Instructions' :
               authMethod === 'password' ? 'Sign in' : 
               otpSent ? 'Verify OTP' : 'Send OTP'}
            </motion.button>
          </div>

          {authMethod === 'otp' && otpSent && !forgotPasswordMode && (
            <div className="text-center">
              <button
                type="button"
                onClick={handleResendOTP}
                className="font-medium text-[#fed402] hover:text-[#e0bc02] focus:outline-none focus:underline transition ease-in-out duration-150 flex items-center justify-center"
              >
                <RefreshCw className="mr-1 h-4 w-4" />
                Resend OTP
              </button>
            </div>
          )}
        </motion.form>
      </div>
    </div>
  );
}