import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Upload, Printer, Plus, Trash2, QrCode, X } from 'lucide-react';
import { supabase } from '../supabaseClient';
import Navigation from './Navigation';
import { showNotification, showError } from '../utils/notificationHandler';
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink, Font } from '@react-pdf/renderer';
import { Html5Qrcode } from 'html5-qrcode';

// Register a custom font (optional)
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    fontFamily: 'Roboto',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  label: {
    border: '1pt solid black',
    padding: 10,
    marginBottom: 10,
    flexDirection: 'row',
  },
  labelContent: {
    flex: 1,
  },
  companyLogo: {
    width: 50,
    height: 50,
    marginBottom: 10,
    objectFit: 'contain',
  },
  partImage: {
    width: 50,
    height: 50,
    marginLeft: 10,
    objectFit: 'contain',
  },
  text: {
    fontSize: 10,
    marginBottom: 5,
  },
});

const PartLabel = ({ part, companyLogo }) => (
  <View style={styles.label}>
    <View style={styles.labelContent}>
      {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
      <Text style={styles.text}>Customer Part: {part.customerPartNumber}</Text>
      <Text style={styles.text}>MHS Part: {part.mhsPartNumber}</Text>
      <Text style={styles.text}>{part.description}</Text>
    </View>
    {part.imageUrl && <Image style={styles.partImage} src={part.imageUrl} />}
  </View>
);

const PartLabelDocument = ({ partNumbers, companyLogo }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        {partNumbers.map((part, index) => (
          <PartLabel key={index} part={part} companyLogo={companyLogo} />
        ))}
      </View>
    </Page>
  </Document>
);

const PartLabelManagement = () => {
  const [partNumbers, setPartNumbers] = useState([]);
  const [newPartNumber, setNewPartNumber] = useState('');
  const [companyLogo, setCompanyLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [html5QrCode, setHtml5QrCode] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [useCustomerPartNumber, setUseCustomerPartNumber] = useState(false);
  const history = useHistory();

  const placeholderImageUrl =
    'https://syd1.digitaloceanspaces.com/cdn-assets.mhs/images/placeholders/mhs_placeholder.png';

  useEffect(() => {
    fetchAccountData();
    checkMobileDevice();
  }, []);

  const checkMobileDevice = () => {
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );
  };

  const fetchAccountData = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('accounts')
          .select('logo_url')
          .eq('id', user.id)
          .single();

        if (error) throw error;

        if (data.logo_url) {
          // Use the signed URL directly
          setCompanyLogo(data.logo_url);
        }
      }
    } catch (error) {
      console.error('Error fetching account data:', error);
      showError(error, 'Failed to fetch account data');
    }
  };

  const handleAddPartNumber = async () => {
    if (!newPartNumber) return;

    setLoading(true);
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');

      let mhsPartNumber = newPartNumber;
      let customerDescription = '';

      if (useCustomerPartNumber) {
        const { data, error } = await supabase
          .from('part_number_mappings')
          .select('mhs_part_number, customer_description')
          .eq('customer_part_number', newPartNumber)
          .eq('user_id', user.id)
          .single();

        if (error) throw error;

        if (!data) {
          throw new Error('Customer part number not found');
        }

        mhsPartNumber = data.mhs_part_number;
        customerDescription = data.customer_description;
      }

      const response = await fetch('/api/enquire/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          partNumber: mhsPartNumber,
          account_id: user.id,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      let imageUrl = data.small_image_url || placeholderImageUrl;

      const partData = {
        customerPartNumber: useCustomerPartNumber ? newPartNumber : mhsPartNumber,
        mhsPartNumber: mhsPartNumber,
        description: customerDescription || data.Description || 'N/A',
        imageUrl: imageUrl, // Use the URL directly
      };

      setPartNumbers((prevPartNumbers) => [...prevPartNumbers, partData]);
      setNewPartNumber('');
      showNotification('Part number added successfully', 'success');
    } catch (error) {
      console.error('Error adding part number:', error);
      showError(error, 'Failed to add part number');
    } finally {
      setLoading(false);
    }
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');

      const fileExt = file.name.split('.').pop();
      const fileName = `${user.id}${Math.random()}.${fileExt}`;
      const filePath = `logos/${fileName}`;

      let { error: uploadError } = await supabase.storage
        .from('company-logos')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data, error: urlError } = await supabase.storage
        .from('company-logos')
        .createSignedUrl(filePath, 60 * 60 * 24 * 365); // 1 year expiry

      if (urlError) throw urlError;

      const { error: updateError } = await supabase
        .from('accounts')
        .update({ logo_url: data.signedUrl })
        .eq('id', user.id);

      if (updateError) throw updateError;

      setCompanyLogo(data.signedUrl);
      showNotification('Company logo uploaded successfully!', 'success');
    } catch (error) {
      console.error('Error uploading logo:', error);
      showError(error, 'Failed to upload logo');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveLogo = async () => {
    try {
      setLoading(true);
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');

      const { data, error: fetchError } = await supabase
        .from('accounts')
        .select('logo_url')
        .eq('id', user.id)
        .single();

      if (fetchError) throw fetchError;

      if (data.logo_url) {
        const fileName = data.logo_url.split('/').pop();
        const { error: deleteError } = await supabase.storage
          .from('company-logos')
          .remove([`logos/${fileName}`]);

        if (deleteError) throw deleteError;
      }

      const { error: updateError } = await supabase
        .from('accounts')
        .update({ logo_url: null })
        .eq('id', user.id);

      if (updateError) throw updateError;

      setCompanyLogo(null);
      showNotification('Company logo removed successfully!', 'success');
    } catch (error) {
      console.error('Error removing logo:', error);
      showError(error, 'Failed to remove logo');
    } finally {
      setLoading(false);
    }
  };

  const startScanner = () => {
    setIsScanning(true);
  };

  const stopScanner = () => {
    if (html5QrCode) {
      html5QrCode
        .stop()
        .then(() => {
          setHtml5QrCode(null);
          setIsScanning(false);
        })
        .catch((err) => {
          console.error('Error stopping scanner:', err);
        });
    } else {
      setIsScanning(false);
    }
  };

  const handleScan = (decodedText) => {
    setNewPartNumber(decodedText);
    stopScanner();
    handleAddPartNumber();
  };

  useEffect(() => {
    let html5QrCodeInstance;

    if (isScanning) {
      // Ensure the <div id="reader"> is in the DOM
      html5QrCodeInstance = new Html5Qrcode('reader');
      setHtml5QrCode(html5QrCodeInstance);

      html5QrCodeInstance
        .start(
          { facingMode: 'environment' },
          {
            fps: 10,
            qrbox: { width: 250, height: 250 },
          },
          handleScan,
          (errorMessage) => {
            console.log(errorMessage);
          }
        )
        .catch((err) => {
          console.error('Unable to start scanning', err);
          showError(err, 'Failed to start scanner');
          setIsScanning(false);
        });
    }

    return () => {
      if (html5QrCodeInstance) {
        html5QrCodeInstance.stop().catch((err) => {
          console.error('Error stopping scanner:', err);
        });
      }
    };
  }, [isScanning]);

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      showNotification('Successfully signed out!', 'success');
      history.push('/login');
    } catch (error) {
      showError(error, 'Failed to sign out. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-[#1a1818]">
      <Navigation onSignOut={handleSignOut} />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-extrabold text-[#fed402] text-center mb-12"
        >
          Part Label Management
        </motion.h1>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyLogo">
              Company Logo
            </label>
            {companyLogo ? (
              <div className="flex items-center">
                <img src={companyLogo} alt="Company Logo" className="h-16 object-contain mr-4" />
                <button
                  onClick={handleRemoveLogo}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                >
                  <X className="mr-2" />
                  Remove Logo
                </button>
              </div>
            ) : (
              <div>
                <input
                  type="file"
                  id="companyLogo"
                  accept="image/*"
                  onChange={handleLogoUpload}
                  className="hidden"
                />
                <label
                  htmlFor="companyLogo"
                  className="cursor-pointer bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded inline-flex items-center"
                >
                  <Upload className="mr-2" />
                  <span>Upload Logo</span>
                </label>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={useCustomerPartNumber}
                onChange={(e) => setUseCustomerPartNumber(e.target.checked)}
                className="mr-2"
              />
              <span className="text-gray-700 text-sm font-bold">Use Customer Part Numbers</span>
            </label>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="partNumber">
              Add Part Number
            </label>
            <div className="flex">
              <input
                type="text"
                id="partNumber"
                value={newPartNumber}
                onChange={(e) => setNewPartNumber(e.target.value)}
                className="shadow appearance-none border rounded-l w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={`Enter ${useCustomerPartNumber ? 'customer' : 'MHS'} part number`}
              />
              <button
                onClick={handleAddPartNumber}
                disabled={loading}
                className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded-r focus:outline-none focus:shadow-outline flex items-center"
              >
                <Plus className="mr-2" />
                Add
              </button>
            </div>
          </div>

          {isMobile && (
            <div className="mb-4">
              <button
                onClick={isScanning ? stopScanner : startScanner}
                className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
              >
                <QrCode className="mr-2" />
                {isScanning ? 'Stop Scanning' : 'Start Scanning'}
              </button>
              {isScanning && (
                <div
                  id="reader"
                  style={{ width: '100%', maxWidth: '600px', margin: '20px auto' }}
                ></div>
              )}
            </div>
          )}

          <div className="mb-4">
            <h2 className="text-xl font-bold text-[#1a1818] mb-2">Added Part Numbers</h2>
            {partNumbers.map((part, index) => (
              <div key={index} className="flex items-center justify-between py-2 border-b">
                <div>
                  <p className="font-semibold">{part.customerPartNumber}</p>
                  <p className="text-sm text-gray-600">{part.description}</p>
                  {useCustomerPartNumber && (
                    <p className="text-xs text-gray-500">MHS Part: {part.mhsPartNumber}</p>
                  )}
                </div>
                <div className="flex items-center">
                  <img
                    src={part.imageUrl}
                    alt={part.description}
                    className="w-12 h-12 object-contain mr-4"
                  />
                  <button
                    onClick={() => {
                      const updatedPartNumbers = partNumbers.filter((_, i) => i !== index);
                      setPartNumbers(updatedPartNumbers);
                    }}
                    className="text-red-500 hover:text-red-700"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="flex space-x-4">
            <PDFDownloadLink
              document={<PartLabelDocument partNumbers={partNumbers} companyLogo={companyLogo} />}
              fileName="part_labels.pdf"
              className={`bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center ${
                partNumbers.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={partNumbers.length === 0}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  'Generating PDF...'
                ) : (
                  <>
                    <Printer className="mr-2" />
                    Generate PDF Labels
                  </>
                )
              }
            </PDFDownloadLink>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-8"
        >
          <button
            onClick={() => history.push('/dashboard')}
            className="w-full flex items-center justify-center py-3 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default PartLabelManagement;