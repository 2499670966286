import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Navigation from './Navigation';
import { useHistory } from 'react-router-dom';
import { Download, ArrowLeft } from 'lucide-react'; 
import { supabase } from '../supabaseClient';
import { toast } from 'react-toastify';

const SlspPromo = () => {
  const history = useHistory();
  const [assignedPromo, setAssignedPromo] = useState(null);
  const [promoData, setPromoData] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    fetchAssignedPromo();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchAssignedPromo = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { data, error } = await supabase
        .from('promo_assignments')
        .select(`
          promo_id,
          monthly_promos (
            id,
            file_name,
            file_path,
            public_url,
            item_no,
            item_designation,
            description,
            special_net_price,
            slsp_net_price
          )
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      
      if (data?.monthly_promos) {
        setAssignedPromo(data.monthly_promos);
        setPromoData(data.monthly_promos);
      } else {
        // If no assigned promo, fetch the latest promo
        fetchLatestPromo();
      }
    } catch (error) {
      console.error('Error fetching assigned promo:', error);
      toast.error('Failed to fetch assigned promo');
      // If error in fetching assigned promo, try to fetch the latest promo
      fetchLatestPromo();
    }
  };

  const fetchLatestPromo = async () => {
    try {
      const { data, error } = await supabase
        .from('monthly_promos')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (error) throw error;
      setPromoData(data);
    } catch (error) {
      console.error('Error fetching latest promo:', error);
      toast.error('Failed to fetch promo data');
    }
  };

  const handleDownloadPromo = async () => {
    try {
      if (!assignedPromo) throw new Error('No assigned promo available');

      const { data, error } = await supabase.storage
        .from('promos')
        .download(assignedPromo.file_path);

      if (error) throw error;

      const blob = new Blob([data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = assignedPromo.file_name;
      link.click();
    } catch (error) {
      console.error('Error downloading promo:', error);
      toast.error('Failed to download promo PDF');
    }
  };

  const currentMonth = new Date().toLocaleString('default', { month: 'long' });

  return (
    <div className="min-h-screen bg-[#1a1818] text-[#fed402]">
      <Navigation /> 

      <div className="p-4 md:p-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-2xl md:text-4xl font-extrabold text-center mb-4"
        >
          {currentMonth} STAUFF Special
        </motion.h1>

        <div className="flex justify-center mb-6">
          <img 
            src={`${process.env.PUBLIC_URL}/mhs_promo.jpg`} 
            alt="Promo" 
            className="mx-auto w-full max-w-sm rounded-md shadow-lg mb-4"
          />
        </div>

        {assignedPromo && (
          <div className="flex justify-center mb-6">
            <motion.button 
              onClick={handleDownloadPromo}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="w-full md:w-auto mt-2 px-4 md:px-6 py-2 bg-[#fed402] text-[#1a1818] font-semibold rounded-full hover:bg-[#e0bc02] transition duration-300 flex items-center justify-center"
            >
              <Download className="w-4 h-4 md:w-5 md:h-5 mr-2" />
              Download Branded Promo
            </motion.button>
          </div>
        )}

        {promoData && (
          <div className="bg-[#fed402] p-4 rounded-lg shadow-md text-left overflow-x-auto">
            <h3 className="text-lg md:text-xl font-bold mb-2 text-[#1a1818]">{promoData.item_designation}</h3>
            <table className="w-full text-left border-collapse text-sm md:text-base">
              <thead>
                <tr>
                  <th className="border-b py-2 text-[#1a1818]">Item No.</th>
                  <th className="border-b py-2 text-[#1a1818]">Description</th>
                  <th className="border-b py-2 text-[#1a1818]">Special Net Price</th>
                  <th className="border-b py-2 text-[#1a1818]">SLSP Net Price*</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b py-2 text-[#1a1818]">{promoData.item_no}</td>
                  <td className="border-b py-2 text-[#1a1818]">{promoData.description}</td>
                  <td className="border-b py-2 text-[#1a1818]">$ {promoData.special_net_price?.toFixed(2)}</td>
                  <td className="border-b py-2 text-[#1a1818]">$ {promoData.slsp_net_price?.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className="mt-8 text-center">
          <button
            onClick={() => history.goBack()}
            className="w-full md:w-auto bg-[#fed402] text-[#1a1818] px-4 py-2 rounded-full font-semibold hover:bg-[#e0bc02] transition duration-200 flex items-center justify-center"
          >
            <ArrowLeft className="w-4 h-4 md:w-5 md:h-5 mr-2" />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default SlspPromo;