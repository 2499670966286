import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { User, Settings, LogOut, ShoppingCart, Menu, X, Download, Bell, Check } from 'lucide-react';
import { supabase } from '../supabaseClient';
import CutoffCountdown from './CutoffCountdown';
import { showNotification } from '../utils/notificationHandler';

const Navigation = ({ onSignOut, isAdmin }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [profileImage, setProfileImage] = useState(null);
  const [showCountdown, setShowCountdown] = useState(localStorage.getItem('showCountdown') !== 'false');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart') || '[]');
    setCartItemCount(cart.length);
    fetchProfileImage();
    fetchUnreadNotificationsCount();
    fetchNotifications();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });

    let notificationSubscription;

    // Subscribe to real-time notifications
    (async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        notificationSubscription = supabase
          .channel(`public:notifications:user_id=eq.${user.id}`)
          .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'notifications', filter: `user_id=eq.${user.id}` }, (payload) => {
            setNotifications((prevNotifications) => [payload.new, ...prevNotifications]);
            setUnreadNotificationsCount((prevCount) => prevCount + 1);
          })
          .subscribe();
      }
    })();

    return () => {
      window.removeEventListener('resize', handleResize);
      if (notificationSubscription) {
        supabase.removeChannel(notificationSubscription);
      }
    };
  }, []);

  const fetchProfileImage = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('accounts')
          .select('avatar_url')
          .eq('id', user.id)
          .single();

        if (error) throw error;

        if (data.avatar_url) {
          setProfileImage(data.avatar_url);
        }
      }
    } catch (error) {
      console.error('Error fetching profile image:', error);
    }
  };

  const fetchUnreadNotificationsCount = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { count, error } = await supabase
          .from('notifications')
          .select('*', { count: 'exact', head: true })
          .eq('user_id', user.id)
          .eq('read', false);

        if (error) throw error;
        setUnreadNotificationsCount(count);
      }
    } catch (error) {
      console.error('Error fetching unread notifications count:', error);
    }
  };

  const fetchNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('notifications')
          .select('*')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false })
          .limit(10);

        if (error) throw error;
        setNotifications(data);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      history.push('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleCountdown = () => {
    const newShowCountdown = !showCountdown;
    setShowCountdown(newShowCountdown);
    localStorage.setItem('showCountdown', newShowCountdown.toString());
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        showNotification('Thank you for installing our app!', 'success');
      } else {
        showNotification('App installation cancelled.', 'info');
      }
      setDeferredPrompt(null);
    } else {
      showNotification('The app is already installed or not available for installation.', 'info');
    }
  };

  const handleNotificationClick = async (notification) => {
    try {
      await handleMarkAsRead(notification);
      // Add navigation logic here if needed
      // history.push(`/orders/${notification.order_id}`);
    } catch (error) {
      console.error('Error handling notification click:', error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { error } = await supabase
          .from('notifications')
          .update({ read: true })
          .eq('user_id', user.id)
          .eq('read', false);

        if (error) throw error;

        // Update state
        setNotifications(notifications.map(notification => ({ ...notification, read: true })));
        setUnreadNotificationsCount(0);
      }
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  const handleMarkAsRead = async (notification) => {
    try {
      const { error } = await supabase
        .from('notifications')
        .update({ read: true })
        .eq('id', notification.id);

      if (error) throw error;

      setNotifications(notifications.map(n =>
        n.id === notification.id ? { ...n, read: true } : n
      ));
      setUnreadNotificationsCount((prevCount) => prevCount - 1);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const NotificationItem = ({ notification }) => (
    <div
      className={`p-2 mb-2 rounded ${notification.read ? 'bg-white' : 'bg-gray-50'} flex justify-between items-center hover:bg-gray-100`}
    >
      <div className="cursor-pointer" onClick={() => handleNotificationClick(notification)}>
        <p className="text-sm text-gray-800">{notification.message}</p>
        <p className="text-xs text-gray-500">{new Date(notification.created_at).toLocaleString()}</p>
      </div>
      {!notification.read && (
        <button
          className="text-gray-400 hover:text-gray-600 ml-2"
          onClick={() => handleMarkAsRead(notification)}
        >
          <Check size={16} />
        </button>
      )}
    </div>
  );

  return (
    <nav className="bg-[#1a1818] shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0">
            <Link to="/dashboard">
              <img
                src="/Logo.png"
                alt="Company Logo"
                className="h-12 w-auto cursor-pointer"
              />
            </Link>
          </div>

          <div className="flex-grow flex justify-center hidden md:flex">
            <span className="text-[#fed402] font-bold text-xl">
              Partner Portal
            </span>
          </div>

          {isMobile && (
            <button
              onClick={toggleMobileMenu}
              className="text-[#fed402] hover:text-white focus:outline-none focus:text-white"
            >
              {mobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          )}

          <div className={`flex items-center space-x-4 ${isMobile ? 'hidden' : 'flex'}`}>
            {showCountdown && <CutoffCountdown />}
            <div className="relative">
              <button
                onClick={() => history.push('/cart')}
                className="bg-[#fed402] flex items-center justify-center w-8 h-8 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#1a1818] focus:ring-[#fed402]"
              >
                <ShoppingCart className="h-5 w-5 text-[#1a1818]" />
              </button>
              {cartItemCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                  {cartItemCount}
                </span>
              )}
            </div>
            <div className="relative">
              <button
                onClick={() => setShowNotifications(!showNotifications)}
                className="bg-[#fed402] flex items-center justify-center w-8 h-8 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#1a1818] focus:ring-[#fed402]"
              >
                <Bell className="h-5 w-5 text-[#1a1818]" />
              </button>
              {unreadNotificationsCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                  {unreadNotificationsCount}
                </span>
              )}
              {showNotifications && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg overflow-hidden z-20">
                  <div className="py-2">
                    <div className="px-4 py-2 text-sm font-semibold bg-gray-100 flex justify-between items-center">
                      <span className="text-gray-800">Notifications</span>
                      <button
                        className="text-blue-500 hover:text-blue-700 text-xs"
                        onClick={handleMarkAllAsRead}
                      >
                        Mark All as Read
                      </button>
                    </div>
                    <div className="max-h-60 overflow-y-auto">
                      {notifications.length > 0 ? (
                        notifications.map(notification => (
                          <NotificationItem key={notification.id} notification={notification} />
                        ))
                      ) : (
                        <p className="px-4 py-2 text-sm text-gray-500">No notifications</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <button
                className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#1a1818] focus:ring-white"
                onClick={() => setIsOpen(!isOpen)}
              >
                {profileImage ? (
                  <img src={profileImage} alt="Profile" className="h-8 w-8 rounded-full object-cover" />
                ) : (
                  <User className="h-8 w-8 rounded-full text-[#1a1818] p-1" />
                )}
              </button>
              {isOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <button 
                    onClick={() => {
                      history.push('/user-settings');
                      setIsOpen(false);
                    }} 
                    className="block px-4 py-2 text-sm text-[#1a1818] hover:bg-[#fed402] w-full text-left"
                  >
                    <Settings className="inline-block mr-2" size={16} />
                    {isAdmin ? 'Admin Settings' : 'User Settings'}
                  </button>
                  <button 
                    onClick={toggleCountdown} 
                    className="block px-4 py-2 text-sm text-[#1a1818] hover:bg-[#fed402] w-full text-left"
                  >
                    {showCountdown ? 'Hide Countdown' : 'Show Countdown'}
                  </button>
                  <button 
                    onClick={handleInstallClick} 
                    className="block px-4 py-2 text-sm text-[#1a1818] hover:bg-[#fed402] w-full text-left"
                  >
                    <Download className="inline-block mr-2" size={16} />
                    Install App
                  </button>
                  <button 
                    onClick={() => {
                      handleSignOut();
                      setIsOpen(false);
                    }} 
                    className="block px-4 py-2 text-sm text-[#1a1818] hover:bg-[#fed402] w-full text-left"
                  >
                    <LogOut className="inline-block mr-2" size={16} /> Sign out
                  </button>
                </motion.div>
              )}
            </div>
          </div>
        </div>

        {isMobile && mobileMenuOpen && (
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/dashboard" className="text-[#fed402] hover:bg-[#fed402] hover:text-[#1a1818] block px-3 py-2 rounded-md text-base font-medium">
              Dashboard
            </Link>
            <Link to="/cart" className="text-[#fed402] hover:bg-[#fed402] hover:text-[#1a1818] block px-3 py-2 rounded-md text-base font-medium">
              Cart ({cartItemCount})
            </Link>
            <Link to="/user-settings" className="text-[#fed402] hover:bg-[#fed402] hover:text-[#1a1818] block px-3 py-2 rounded-md text-base font-medium">
              {isAdmin ? 'Admin Settings' : 'User Settings'}
            </Link>
            <button 
              onClick={toggleCountdown}
              className="text-[#fed402] hover:bg-[#fed402] hover:text-[#1a1818] block px-3 py-2 rounded-md text-base font-medium w-full text-left"
            >
              {showCountdown ? 'Hide Countdown' : 'Show Countdown'}
            </button>
            <button 
              onClick={handleInstallClick}
              className="text-[#fed402] hover:bg-[#fed402] hover:text-[#1a1818] block px-3 py-2 rounded-md text-base font-medium w-full text-left"
            >
              Install App
            </button>
            <button 
              onClick={handleSignOut}
              className="text-[#fed402] hover:bg-[#fed402] hover:text-[#1a1818] block px-3 py-2 rounded-md text-base font-medium w-full text-left"
            >
              Sign out
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;