// RecentOrders.js

import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, FileText, Search, ChevronUp, ChevronDown, Truck, MessageSquare, Bell, AlertTriangle } from 'lucide-react';
import { supabase } from '../supabaseClient';
import Navigation from './Navigation';
import { showNotification, showError } from '../utils/notificationHandler';
import { format } from 'date-fns';

const ORDER_STATUSES = [
  'All',
  'Pending',
  'Complete',
  'In Progress',
  'Cancelled',
  'On Hold',
  'On Order',
];

const RecentOrders = () => {
  const [orders, setOrders] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [loadingNotifications, setLoadingNotifications] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
  const [statusFilter, setStatusFilter] = useState('All');
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [selectedOrderNote, setSelectedOrderNote] = useState('');
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchRecentOrders();
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRecentOrders = async () => {
    try {
      setLoadingOrders(true);
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) throw new Error('No user found');

      let query = supabase
        .from('orders')
        .select(`
          id,
          order_id,
          order_number,
          status,
          created_at,
          cart_items,
          mhs_reference,
          shipping_status,
          connote,
          notes,
          xml_url
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      const { data, error } = await query;

      if (error) throw error;

      setOrders(data);
    } catch (error) {
      console.error('Error fetching recent orders:', error);
      showError(error, 'Failed to load recent orders. Please try again later.');
    } finally {
      setLoadingOrders(false);
    }
  };

  const fetchNotifications = async () => {
    try {
      setLoadingNotifications(true);
      const { data: { user } } = await supabase.auth.getUser();

      if (!user) throw new Error('No user found');

      const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      setNotifications(data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      showError(error, 'Failed to load notifications. Please try again later.');
    } finally {
      setLoadingNotifications(false);
    }
  };

  const handleDownloadXML = async (xmlUrl) => {
    try {
      const { data, error } = await supabase.storage
        .from('orders')
        .download(xmlUrl.split('/').pop());

      if (error) throw error;

      const blob = new Blob([data], { type: 'application/xml' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'order.xml';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      showNotification('XML file downloaded successfully', 'success');
    } catch (error) {
      console.error('Error downloading XML:', error);
      showError(error, 'Failed to download XML file. Please try again.');
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedOrders = useMemo(() => {
    let sortableOrders = [...orders];
    if (sortConfig.key) {
      sortableOrders.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Handle nested fields (e.g., 'created_at')
        if (sortConfig.key === 'created_at') {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableOrders;
  }, [orders, sortConfig]);

  const filteredOrders = useMemo(() => {
    return sortedOrders.filter(order => 
      (order.order_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (order.mhs_reference && order.mhs_reference.toLowerCase().includes(searchTerm.toLowerCase()))) &&
      (statusFilter === 'All' || order.status === statusFilter)
    );
  }, [sortedOrders, searchTerm, statusFilter]);

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      showNotification('Successfully signed out!', 'success');
      history.push('/login');
    } catch (error) {
      showError(error, error.error_description || error.message);
    }
  };

  const handleViewNote = (note) => {
    setSelectedOrderNote(note);
    setShowNoteModal(true);
  };

  const handleMarkNotificationsAsRead = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();

      if (!user) throw new Error('No user found');

      const { error } = await supabase
        .from('notifications')
        .update({ read: true })
        .eq('user_id', user.id);

      if (error) throw error;

      // Refresh notifications
      fetchNotifications();
    } catch (error) {
      console.error('Error marking notifications as read:', error);
      showError(error, 'Failed to mark notifications as read. Please try again.');
    }
  };

  const unreadNotificationsCount = notifications.filter(n => !n.read).length;

  return (
    <div className="min-h-screen bg-[#1a1818]">
      <Navigation onSignOut={handleSignOut}>
        {/* Notification Icon */}
        <button
          onClick={() => setShowNotificationsModal(true)}
          className="relative text-[#fed402] focus:outline-none"
        >
          <Bell size={24} />
          {unreadNotificationsCount > 0 && (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-black transform translate-x-1/2 -translate-y-1/2 bg-[#fed402] rounded-full">
              {unreadNotificationsCount}
            </span>
          )}
        </button>
      </Navigation>
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-2xl md:text-4xl font-extrabold text-[#fed402] text-center mb-6 md:mb-12"
        >
          Recent Orders
        </motion.h1>
        
        {loadingOrders ? (
          <div className="text-center text-[#fed402]">Loading...</div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between space-y-2 md:space-y-0">
              <div className="relative md:w-1/2">
                <input
                  type="text"
                  placeholder="Search by Order Number or MHS Reference"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-[#fed402] text-black"
                />
                <Search className="absolute right-3 top-2.5 text-gray-400" size={20} />
              </div>
              <div className="md:w-1/4">
                <select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-[#fed402] text-black"
                >
                  {ORDER_STATUSES.map((status) => (
                    <option key={status} value={status}>{status}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white rounded-lg overflow-hidden">
                <thead className="bg-gray-100">
                  <tr>
                    {[
                      { key: 'order_id', label: 'Order ID' },
                      { key: 'order_number', label: 'Order Number' },
                      { key: 'created_at', label: 'Date' },
                      { key: 'items', label: 'Items' },
                      { key: 'total', label: 'Total' },
                      { key: 'status', label: 'Status' },
                      { key: 'mhs_reference', label: 'MHS Reference' },
                      { key: 'shipping_status', label: 'Shipping Status' },
                      { key: 'connote', label: 'ConNote' },
                      { key: 'actions', label: 'Actions' },
                    ].map((column) => (
                      <th
                        key={column.key}
                        className="px-4 py-2 cursor-pointer text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                        onClick={() => column.key !== 'actions' && handleSort(column.key)}
                      >
                        <div className="flex items-center">
                          {column.label}
                          {sortConfig.key === column.key && (
                            sortConfig.direction === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders.map((order) => (
                    <tr key={order.id} className="border-t">
                      <td className="px-4 py-2">{order.order_id}</td>
                      <td className="px-4 py-2">{order.order_number}</td>
                      <td className="px-4 py-2">{format(new Date(order.created_at), 'PPpp')}</td>
                      <td className="px-4 py-2">{order.cart_items.length}</td>
                      <td className="px-4 py-2">${order.cart_items.reduce((sum, item) => sum + (item.price * item.quantity), 0).toFixed(2)}</td>
                      <td className="px-4 py-2">{order.status || 'Pending'}</td>
                      <td className="px-4 py-2">{order.mhs_reference || 'N/A'}</td>
                      <td className="px-4 py-2">{order.shipping_status || 'Not Shipped'}</td>
                      <td className="px-4 py-2">{order.connote || 'N/A'}</td>
                      <td className="px-4 py-2">
                        <div className="flex flex-wrap space-x-2">
                          <button
                            onClick={() => handleDownloadXML(order.xml_url)}
                            className="bg-[#fed402] text-[#1a1818] py-1 px-2 rounded-lg font-semibold hover:bg-[#e0bc02] transition-colors duration-200 flex items-center"
                          >
                            <FileText className="mr-1" size={16} />
                            XML
                          </button>
                          {order.shipping_status && (
                            <button
                              onClick={() => showNotification(`Shipping Status: ${order.shipping_status}${order.connote ? `\nConNote: ${order.connote}` : ''}`, 'info')}
                              className="bg-blue-500 text-white py-1 px-2 rounded-lg font-semibold hover:bg-blue-600 transition-colors duration-200 flex items-center"
                            >
                              <Truck className="mr-1" size={16} />
                              Shipping
                            </button>
                          )}
                          {order.notes && (
                            <button
                              onClick={() => handleViewNote(order.notes)}
                              className="bg-green-500 text-white py-1 px-2 rounded-lg font-semibold hover:bg-green-600 transition-colors duration-200 flex items-center"
                            >
                              <MessageSquare className="mr-1" size={16} />
                              Note
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </motion.div>
        )}
        
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-6 md:mt-8"
        >
          <button
            onClick={() => history.push('/dashboard')}
            className="w-full flex items-center justify-center py-3 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </button>
        </motion.div>

        {/* Note Modal */}
        {showNoteModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4 py-8">
              <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
                <div className="p-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Order Note</h3>
                  <p className="text-sm text-gray-700">{selectedOrderNote}</p>
                </div>
                <div className="px-6 py-4 bg-gray-100 text-right">
                  <button
                    onClick={() => setShowNoteModal(false)}
                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors duration-200"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Notifications Modal */}
        {showNotificationsModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4 py-8">
              <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
                <div className="p-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Notifications</h3>
                  {loadingNotifications ? (
                    <div className="text-center">Loading...</div>
                  ) : notifications.length === 0 ? (
                    <p className="text-sm text-gray-700">No notifications.</p>
                  ) : (
                    <ul className="space-y-2">
                      {notifications.map((notification) => (
                        <li key={notification.id} className={`p-2 rounded ${notification.read ? 'bg-gray-100' : 'bg-yellow-100'}`}>
                          <p className="text-sm text-gray-800">{notification.message}</p>
                          <p className="text-xs text-gray-500">{format(new Date(notification.created_at), 'PPpp')}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="px-6 py-4 bg-gray-100 flex justify-between">
                  <button
                    onClick={() => {
                      setShowNotificationsModal(false);
                      handleMarkNotificationsAsRead();
                    }}
                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors duration-200"
                  >
                    Mark All as Read
                  </button>
                  <button
                    onClick={() => setShowNotificationsModal(false)}
                    className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors duration-200"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default RecentOrders;