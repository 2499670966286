import React from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, AlertTriangle } from 'lucide-react';

const NotFound = () => {
  const history = useHistory();

  return (
    <div className="min-h-screen bg-[#1a1818] flex items-center justify-center">
      <div className="max-w-md w-full space-y-8 p-8">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <AlertTriangle className="mx-auto h-24 w-24 text-[#fed402]" />
          <h2 className="mt-6 text-3xl font-extrabold text-[#fed402]">404 - Page Not Found</h2>
          <p className="mt-2 text-lg text-[#fed402] opacity-80">
            Oops! The page you're looking for doesn't exist.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <button
            onClick={() => history.push('/dashboard')}
            className="w-full flex items-center justify-center py-3 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default NotFound;