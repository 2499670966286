import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeft, Plus, Trash2, Search, Edit2, Save, X, Upload, Download, AlertCircle, Loader, ArrowUp, ArrowDown, CheckSquare } from 'lucide-react';
import { supabase } from '../supabaseClient';
import Navigation from './Navigation';
import { showNotification, showError } from '../utils/notificationHandler';
import * as XLSX from 'xlsx';

const LoadingOverlay = ({ message }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center">
      <Loader className="w-12 h-12 text-[#fed402] animate-spin" />
      <p className="mt-4 text-lg font-semibold text-[#1a1818]">{message}</p>
    </div>
  </div>
);

const ConfirmationDialog = ({ isOpen, onClose, onConfirm, message }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-white p-6 rounded-lg shadow-xl"
        >
          <p className="text-lg font-semibold mb-4">{message}</p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Confirm
            </button>
          </div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

const PartNumberManagement = () => {
  const [loading, setLoading] = useState(false);
  const [customerPartNumber, setCustomerPartNumber] = useState('');
  const [customerDescription, setCustomerDescription] = useState('');
  const [mhsPartNumber, setMhsPartNumber] = useState('');
  const [mhsDescription, setMhsDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [mappings, setMappings] = useState([]);
  const [userId, setUserId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [editingId, setEditingId] = useState(null);
  const [editForm, setEditForm] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedMappings, setSelectedMappings] = useState([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const history = useHistory();

  const placeholderImageUrl = "https://syd1.digitaloceanspaces.com/cdn-assets.mhs/images/placeholders/mhs_placeholder.png";

  useEffect(() => {
    fetchUserData();
    fetchMappings();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUserId(user.id);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      showError(error, 'Failed to fetch user data');
    }
  };

  const fetchMappings = async () => {
    try {
      const { data, error } = await supabase
        .from('part_number_mappings')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setMappings(data);
    } catch (error) {
      console.error('Error fetching mappings:', error);
      showError(error, 'Failed to fetch part number mappings');
    }
  };

  const handleMhsPartNumberSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/enquire/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ partNumber: mhsPartNumber }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.Description) {
        setMhsDescription(data.Description);
        setImageUrl(data.small_image_url || placeholderImageUrl);
        showNotification('MHS part number found', 'success');
      } else {
        showNotification('MHS part number not found', 'error');
        setMhsDescription('');
        setImageUrl('');
      }
    } catch (error) {
      console.error('Error searching MHS part number:', error);
      showError(error, 'Failed to search MHS part number');
      setMhsDescription('');
      setImageUrl('');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!mhsDescription) {
      showNotification('Please search for a valid MHS part number first', 'error');
      return;
    }
    try {
      const { data, error } = await supabase
        .from('part_number_mappings')
        .insert([
          {
            user_id: userId,
            customer_part_number: customerPartNumber,
            customer_description: customerDescription,
            mhs_part_number: mhsPartNumber,
            mhs_description: mhsDescription,
            image_url: imageUrl,
          }
        ]);

      if (error) throw error;
      showNotification('Part number mapping added successfully', 'success');
      setCustomerPartNumber('');
      setCustomerDescription('');
      setMhsPartNumber('');
      setMhsDescription('');
      setImageUrl('');
      fetchMappings();
    } catch (error) {
      console.error('Error adding mapping:', error);
      showError(error, 'Failed to add part number mapping');
    }
  };

  const handleDelete = async (id) => {
    setConfirmAction(() => async () => {
      try {
        const { error } = await supabase
          .from('part_number_mappings')
          .delete()
          .eq('id', id);

        if (error) throw error;
        showNotification('Part number mapping deleted successfully', 'success');
        fetchMappings();
      } catch (error) {
        console.error('Error deleting mapping:', error);
        showError(error, 'Failed to delete part number mapping');
      }
    });
    setIsConfirmDialogOpen(true);
  };

  const handleEdit = (mapping) => {
    setEditingId(mapping.id);
    setEditForm({ ...mapping });
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditForm({});
  };

  const handleSaveEdit = async () => {
    try {
      const { error } = await supabase
        .from('part_number_mappings')
        .update(editForm)
        .eq('id', editingId);

      if (error) throw error;
      showNotification('Part number mapping updated successfully', 'success');
      setEditingId(null);
      setEditForm({});
      fetchMappings();
    } catch (error) {
      console.error('Error updating mapping:', error);
      showError(error, 'Failed to update part number mapping');
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && (selectedFile.type === 'text/csv' || selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      setFile(selectedFile);
    } else {
      showNotification('Please select a valid CSV or Excel file', 'error');
    }
  };

  const handleBulkUpload = async () => {
    if (!file) {
      showNotification('Please select a file to upload', 'error');
      return;
    }

    setUploading(true);
    setIsSearching(true);
    try {
      const fileData = await readFileAsync(file);
      const mappings = parseFileData(fileData);
      await processAndValidateMappings(mappings);
      showNotification('Bulk upload completed successfully', 'success');
      setFile(null);
      fetchMappings();
    } catch (error) {
      showError(error, 'Failed to process the upload');
    } finally {
      setUploading(false);
      setIsSearching(false);
    }
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });
  };

  const parseFileData = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    return XLSX.utils.sheet_to_json(sheet);
  };

  const processAndValidateMappings = async (mappings) => {
    for (const mapping of mappings) {
      if (!mapping.CustomerPartNumber || !mapping.MHSPartNumber) {
        throw new Error('Invalid mapping: Customer Part Number and MHS Part Number are required');
      }

      // Validate MHS Part Number
      const response = await fetch('/api/enquire/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ partNumber: mapping.MHSPartNumber }),
      });

      if (!response.ok) {
        throw new Error(`Invalid MHS Part Number: ${mapping.MHSPartNumber}`);
      }

      const data = await response.json();

      // If valid, add to database
      const { error } = await supabase.from('part_number_mappings').insert({
        user_id: userId,
        customer_part_number: mapping.CustomerPartNumber,
        mhs_part_number: mapping.MHSPartNumber,
        customer_description: mapping.CustomerDescription || '',
        mhs_description: data.Description || '',
        image_url: data.small_image_url || placeholderImageUrl,
      });

      if (error) throw error;
    }
  };

  const downloadTemplate = (format) => {
    const data = [
      { CustomerPartNumber: 'CUST001', MHSPartNumber: 'MHS001', CustomerDescription: 'Custom Hydraulic Pump' },
      { CustomerPartNumber: 'CUST002', MHSPartNumber: 'MHS002', CustomerDescription: 'Industrial Valve Assembly' },
    ];

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Mappings");

    if (format === 'xlsx') {
      XLSX.writeFile(wb, "part_number_mapping_template.xlsx");
    } else if (format === 'csv') {
      XLSX.writeFile(wb, "part_number_mapping_template.csv", { bookType: "csv" });
    }
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      showNotification('Successfully signed out!', 'success');
      history.push('/login');
    } catch (error) {
      showError(error, 'Failed to sign out. Please try again.');
    }
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleExport = () => {
    const dataToExport = mappings.map(mapping => ({
      'Customer Part Number': mapping.customer_part_number,
      'Customer Description': mapping.customer_description,
      'MHS Part Number': mapping.mhs_part_number,
      'MHS Description': mapping.mhs_description,
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Mappings");
    XLSX.writeFile(wb, "part_number_mappings_export.xlsx");
  };

  const handleSelectMapping = (id) => {
    setSelectedMappings(prev => 
      prev.includes(id) ? prev.filter(mappingId => mappingId !== id) : [...prev, id]
    );
  };

  const handleBatchDelete = () => {
    if (selectedMappings.length === 0) {
      showNotification('Please select mappings to delete', 'error');
      return;
    }

    setConfirmAction(() => async () => {
      try {
        const { error } = await supabase
          .from('part_number_mappings')
          .delete()
          .in('id', selectedMappings);

        if (error) throw error;
        showNotification('Selected mappings deleted successfully', 'success');
        setSelectedMappings([]);
        fetchMappings();
      } catch (error) {
        console.error('Error deleting mappings:', error);
        showError(error, 'Failed to delete selected mappings');
      }
    });
    setIsConfirmDialogOpen(true);
  };

  const filteredMappings = mappings.filter(mapping =>
    mapping.customer_part_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
    mapping.mhs_part_number.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedMappings = [...filteredMappings].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedMappings.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const SortButton = ({ field, label }) => (
    <th 
      scope="col" 
      className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
      onClick={() => handleSort(field)}
    >
      <div className="flex items-center">
        {label}
        {sortField === field && (
          sortDirection === 'asc' ? <ArrowUp className="ml-1 h-4 w-4" /> : <ArrowDown className="ml-1 h-4 w-4" />
        )}
      </div>
    </th>
  );

  return (
    <div className="min-h-screen bg-[#1a1818]">
      <Navigation onSignOut={handleSignOut} />
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-2xl md:text-4xl font-extrabold text-[#fed402] text-center mb-8 md:mb-12"
        >
          Part Number Management
        </motion.h1>
        
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-lg shadow-md p-4 md:p-6 mb-8"
        >
          <h2 className="text-2xl font-bold text-[#1a1818] mb-4">Bulk Part Number Upload</h2>
          
          <div className="mb-4">
            <input
              type="file"
              onChange={handleFileChange}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              className="hidden"
              id="file-upload"
            />
            <label
              htmlFor="file-upload"
              className="cursor-pointer bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <Upload className="mr-2" />
              <span>{file ? file.name : 'Choose file'}</span>
            </label>
          </div>

          <button
            onClick={handleBulkUpload}
            disabled={!file || uploading}
            className={`bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded ${(!file || uploading) ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {uploading ? 'Uploading...' : 'Upload and Process'}
          </button>

          <div className="mt-8">
            <h3 className="text-lg font-semibold mb-2">Download Templates</h3>
            <button
              onClick={() => downloadTemplate('xlsx')}
              className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded mr-2"
            >
              <Download className="inline-block mr-2" />
              Excel Template
            </button>
            <button
              onClick={() => downloadTemplate('csv')}
              className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded"
            >
              <Download className="inline-block mr-2" />
              CSV Template
            </button>
          </div>

          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
            className="mt-8 bg-[#fed402] rounded-lg p-4"
          >
            <div className="flex items-start">
              <AlertCircle className="flex-shrink-0 h-5 w-5 text-[#1a1818] mt-0.5" />
              <div className="ml-3">
                <h3 className="text-sm font-medium text-[#1a1818]">Important Notes:</h3>
                <div className="mt-2 text-sm text-[#1a1818]">
                  <ul className="list-disc pl-5 space-y-1">
                    <li>File must be in CSV or Excel format</li>
                    <li>Required columns: CustomerPartNumber, MHSPartNumber</li>
                    <li>Optional column: CustomerDescription</li>
                    <li>MHS Part Numbers will be validated against our system</li>
                    <li>Invalid MHS Part Numbers will cause the upload to fail</li>
                    <li>Existing mappings will be overwritten</li>
                  </ul>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-lg shadow-md p-4 md:p-6 mb-8"
        >
          <h2 className="text-2xl font-bold text-[#1a1818] mb-4">Add Individual Mapping</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="customerPartNumber" className="block text-sm font-medium text-gray-700">Customer Part Number</label>
              <input
                type="text"
                name="customerPartNumber"
                id="customerPartNumber"
                value={customerPartNumber}
                onChange={(e) => setCustomerPartNumber(e.target.value)}
                className="mt-1 focus:ring-[#fed402] focus:border-[#fed402] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label htmlFor="customerDescription" className="block text-sm font-medium text-gray-700">Customer Description</label>
              <input
                type="text"
                name="customerDescription"
                id="customerDescription"
                value={customerDescription}
                onChange={(e) => setCustomerDescription(e.target.value)}
                className="mt-1 focus:ring-[#fed402] focus:border-[#fed402] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="mhsPartNumber" className="block text-sm font-medium text-gray-700">MHS Part Number</label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="mhsPartNumber"
                  id="mhsPartNumber"
                  value={mhsPartNumber}
                  onChange={(e) => setMhsPartNumber(e.target.value)}
                  className="focus:ring-[#fed402] focus:border-[#fed402] flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                  required
                />
                <button
                  type="button"
                  onClick={handleMhsPartNumberSearch}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-r-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402]"
                >
                  {loading ? (
                    <motion.div
                      animate={{ rotate: 360 }}
                      transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    >
                      <Search className="h-5 w-5 text-gray-400" />
                    </motion.div>
                  ) : (
                    <Search className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
            </div>
            {mhsDescription && (
              <div>
                <p className="text-sm text-gray-500">{mhsDescription}</p>
                {imageUrl && (
                  <img src={imageUrl} alt="MHS Part" className="mt-2 w-32 h-32 object-contain" />
                )}
              </div>
            )}
            <div>
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-[#1a1818] bg-[#fed402] hover:bg-[#e0bc02] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402]"
              >
                <Plus className="mr-2 h-5 w-5" />
                Add Mapping
              </button>
            </div>
          </form>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="bg-white rounded-lg shadow-md p-4 md:p-6 mb-8"
        >
          <h2 className="text-xl md:text-2xl font-bold text-[#1a1818] mb-4">Your Part Number Mappings</h2>
          <div className="mb-4 flex justify-between items-center">
            <input
              type="text"
              placeholder="Search mappings..."
              className="w-full md:w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#fed402] focus:border-[#fed402]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="flex space-x-2">
              <button
                onClick={handleExport}
                className="bg-[#fed402] hover:bg-[#e0bc02] text-[#1a1818] font-bold py-2 px-4 rounded flex items-center"
              >
                <Download className="mr-2 h-5 w-5" />
                Export
              </button>
              <button
                onClick={handleBatchDelete}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded flex items-center"
                disabled={selectedMappings.length === 0}
              >
                <Trash2 className="mr-2 h-5 w-5" />
                Delete Selected
              </button>
            </div>
          </div>
          {currentItems.length === 0 ? (
            <p className="text-gray-500">No mappings found.</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <input
                        type="checkbox"
                        checked={selectedMappings.length === currentItems.length}
                        onChange={() => {
                          if (selectedMappings.length === currentItems.length) {
                            setSelectedMappings([]);
                          } else {
                            setSelectedMappings(currentItems.map(item => item.id));
                          }
                        }}
                        className="form-checkbox h-5 w-5 text-[#fed402]"
                      />
                    </th>
                    <SortButton field="customer_part_number" label="Customer Part Number" />
                    <SortButton field="customer_description" label="Customer Description" />
                    <SortButton field="mhs_part_number" label="MHS Part Number" />
                    <SortButton field="mhs_description" label="MHS Description" />
                    <th scope="col" className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Image</th>
                    <th scope="col" className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentItems.map((mapping) => (
                    <tr key={mapping.id}>
                      <td className="px-3 md:px-6 py-4 whitespace-nowrap">
                        <input
                          type="checkbox"
                          checked={selectedMappings.includes(mapping.id)}
                          onChange={() => handleSelectMapping(mapping.id)}
                          className="form-checkbox h-5 w-5 text-[#fed402]"
                        />
                      </td>
                      {editingId === mapping.id ? (
                        <>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap">
                            <input
                              type="text"
                              value={editForm.customer_part_number}
                              onChange={(e) => setEditForm({ ...editForm, customer_part_number: e.target.value })}
                              className="w-full px-2 py-1 border border-gray-300 rounded-md"
                            />
                          </td>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap">
                            <input
                              type="text"
                              value={editForm.customer_description}
                              onChange={(e) => setEditForm({ ...editForm, customer_description: e.target.value })}
                              className="w-full px-2 py-1 border border-gray-300 rounded-md"
                            />
                          </td>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap">
                            <input
                              type="text"
                              value={editForm.mhs_part_number}
                              onChange={(e) => setEditForm({ ...editForm, mhs_part_number: e.target.value })}
                              className="w-full px-2 py-1 border border-gray-300 rounded-md"
                            />
                          </td>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap">
                            <input
                              type="text"
                              value={editForm.mhs_description}
                              onChange={(e) => setEditForm({ ...editForm, mhs_description: e.target.value })}
                              className="w-full px-2 py-1 border border-gray-300 rounded-md"
                            />
                          </td>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap">
                            <img src={editForm.image_url} alt="Part" className="w-16 h-16 object-contain" />
                          </td>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <button onClick={handleSaveEdit} className="text-green-600 hover:text-green-900 mr-2">
                              <Save className="h-5 w-5" />
                            </button>
                            <button onClick={handleCancelEdit} className="text-red-600 hover:text-red-900">
                              <X className="h-5 w-5" />
                            </button>
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">{mapping.customer_part_number}</td>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">{mapping.customer_description}</td>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">{mapping.mhs_part_number}</td>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">{mapping.mhs_description}</td>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {mapping.image_url && (
                              <img src={mapping.image_url} alt="Part" className="w-16 h-16 object-contain" />
                            )}
                          </td>
                          <td className="px-3 md:px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <button
                              onClick={() => handleEdit(mapping)}
                              className="text-indigo-600 hover:text-indigo-900 mr-2"
                            >
                              <Edit2 className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleDelete(mapping.id)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <Trash2 className="h-5 w-5" />
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="mt-4 flex justify-center">
            {Array.from({ length: Math.ceil(sortedMappings.length / itemsPerPage) }, (_, i) => (
              <button
                key={i}
                onClick={() => paginate(i + 1)}
                className={`mx-1 px-3 py-1 rounded ${
                  currentPage === i + 1 ? 'bg-[#fed402] text-[#1a1818]' : 'bg-gray-200 text-gray-700'
                }`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </motion.div>
        
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-8"
        >
          <button
            onClick={() => history.push('/dashboard')}
            className="w-full flex items-center justify-center py-3 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </button>
        </motion.div>
      </div>
      <AnimatePresence>
        {isSearching && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoadingOverlay message="Processing part numbers..." />
          </motion.div>
        )}
      </AnimatePresence>
      <ConfirmationDialog
        isOpen={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={() => {
          confirmAction();
          setIsConfirmDialogOpen(false);
        }}
        message="Are you sure you want to delete the selected mapping(s)?"
      />
    </div>
  );
};

export default PartNumberManagement;