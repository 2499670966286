import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, ArrowLeft, Box, DollarSign, Image as ImageIcon, FileText, Star, ChevronDown, ChevronUp, Info, Loader } from 'lucide-react';
import Navigation from './Navigation';
import SearchHistoryAndFavorites from './SearchHistoryAndFavorites';
import NotifyMeButton from './NotifyMeButton';
import { showNotification, showError } from '../utils/notificationHandler';

const LoadingOverlay = ({ message }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center">
      <Loader className="w-12 h-12 text-[#fed402] animate-spin" />
      <p className="mt-4 text-lg font-semibold text-[#1a1818]">{message}</p>
    </div>
  </div>
);

const SpecificationItem = ({ label, value }) => (
  <div className="flex justify-between items-center py-2 border-b border-gray-200 last:border-b-0">
    <span className="font-medium text-gray-600">{label}:</span>
    <span className="text-gray-800">{value}</span>
  </div>
);

const InfoCard = ({ icon, title, value, addToCart }) => (
  <motion.div 
    whileHover={{ scale: 1.05 }}
    className="bg-white rounded-lg p-4 md:p-6 shadow-md flex flex-col"
  >
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        <div className="p-2 bg-[#fed402] rounded-full mr-4">
          {React.cloneElement(icon, { className: "text-[#1a1818] w-4 h-4 md:w-6 md:h-6" })}
        </div>
        <h3 className="text-base md:text-lg font-semibold text-[#1a1818]">{title}</h3>
      </div>
      {addToCart && (
        <button
          onClick={addToCart}
          className="bg-[#fed402] text-[#1a1818] py-1 px-2 md:py-2 md:px-4 rounded-lg text-sm md:text-base font-semibold hover:bg-[#e0bc02] transition-colors duration-200 flex items-center"
        >
          Add to Cart
        </button>
      )}
    </div>
    <div className="text-[#1a1818] flex-grow text-sm md:text-base">
      {typeof value === 'string' || typeof value === 'number' ? (
        <p>{value}</p>
      ) : (
        value
      )}
    </div>
  </motion.div>
);

const BOMComponent = ({ bom, addToCart, canViewComponents }) => {
  const [buildQty, setBuildQty] = useState(1);
  const [availabilityCheck, setAvailabilityCheck] = useState(null);

  const checkAvailability = () => {
    const availability = bom.components.map(component => {
      const requiredQty = component.quantity * buildQty;
      const available = component.qty_available >= requiredQty;
      return {
        part_number: component.part_number,
        description: component.description,
        required: requiredQty,
        available: component.qty_available,
        status: available ? 'Available' : 'Insufficient',
      };
    });

    const canBuild = availability.every(item => item.status === 'Available');
    setAvailabilityCheck({ canBuild, components: availability });
  };

  return (
    <div className="bg-white rounded-lg p-4 md:p-6 shadow-md">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
        <h3 className="text-lg md:text-xl font-semibold mb-2 md:mb-0">{bom.bom_description}</h3>
        <button
          onClick={() => addToCart(bom)}
          className="bg-[#fed402] text-[#1a1818] py-2 px-4 rounded-lg font-semibold hover:bg-[#e0bc02] transition-colors duration-200 flex items-center w-full md:w-auto justify-center md:justify-start mt-2 md:mt-0"
        >
          Add to Cart
        </button>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
        <p className="mb-2 md:mb-0">BOM Code: <span className="font-medium">{bom.bom_code}</span></p>
        <div>
          <p className="text-base md:text-lg">Base Price: <span className="font-semibold">${parseFloat(bom.bom_base_price).toFixed(2)}</span></p>
          {bom.bom_buy_price !== undefined && bom.bom_buy_price !== null && (
            <p className="text-base md:text-lg">Buy Price: <span className="font-semibold text-green-600">${parseFloat(bom.bom_buy_price).toFixed(2)}</span></p>
          )}
        </div>
      </div>
      
      <div className="mb-4">
        <label htmlFor="buildQty" className="block text-sm font-medium text-gray-700">Build Quantity:</label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type="number"
            name="buildQty"
            id="buildQty"
            className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
            value={buildQty}
            onChange={(e) => setBuildQty(Math.max(1, parseInt(e.target.value)))}
            min="1"
          />
          <button
            type="button"
            onClick={checkAvailability}
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Check Availability
          </button>
        </div>
      </div>

      {availabilityCheck && (
        <div className="mb-4 p-4 border rounded-md bg-gray-50">
          <h4 className="font-semibold mb-2">Availability Check Results:</h4>
          <p className={`font-bold ${availabilityCheck.canBuild ? 'text-green-600' : 'text-red-600'}`}>
            {availabilityCheck.canBuild ? 'Can build' : 'Cannot build'} {buildQty} units
          </p>
          {canViewComponents && (
            <ul className="mt-2 space-y-2">
              {availabilityCheck.components.map((item, index) => (
                <li key={index} className={`flex justify-between ${item.status === 'Available' ? 'text-green-600' : 'text-red-600'}`}>
                  <span>{item.part_number} - {item.description}</span>
                  <span>Required: {item.required}, Available: {item.available}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {canViewComponents && (
        <>
          <h4 className="font-semibold mb-2">Components:</h4>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-2 md:px-4 py-2 text-left text-xs md:text-sm">Part Number</th>
                  <th className="px-2 md:px-4 py-2 text-left text-xs md:text-sm">Description</th>
                  <th className="px-2 md:px-4 py-2 text-right text-xs md:text-sm">Quantity</th>
                  <th className="px-2 md:px-4 py-2 text-right text-xs md:text-sm">Available</th>
                </tr>
              </thead>
              <tbody>
                {bom.components.map((component, index) => (
                  <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                    <td className="border px-2 md:px-4 py-2 text-xs md:text-sm">{component.part_number}</td>
                    <td className="border px-2 md:px-4 py-2 text-xs md:text-sm">{component.description}</td>
                    <td className="border px-2 md:px-4 py-2 text-right text-xs md:text-sm">{component.quantity}</td>
                    <td className="border px-2 md:px-4 py-2 text-right text-xs md:text-sm">{component.qty_available}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      <p className="mt-4 text-xs md:text-sm text-gray-600 italic">{bom.note}</p>
    </div>
  );
};

const PartEnquiry = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('exact');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [crossReferences, setCrossReferences] = useState(null);
  const [productNotAllowed, setProductNotAllowed] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [canViewBOMComponents, setCanViewBOMComponents] = useState(true);
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const [accountId, setAccountId] = useState(localStorage.getItem('accountId'));
  const [isSearching, setIsSearching] = useState(false);
  const [canViewFullStock, setCanViewFullStock] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const addToSearchHistory = useCallback(async (term, type) => {
    if (userId) {
      const { error } = await supabase
        .from('search_history')
        .insert({ user_id: userId, part_number: term, search_type: type });
  
      if (error) {
        console.error('Error adding search to history:', error);
      } else {
        const { data, error: fetchError } = await supabase
          .from('search_history')
          .select('part_number, search_type')
          .eq('user_id', userId)
          .order('created_at', { ascending: false })
          .limit(40);
  
        if (fetchError) {
          console.error('Error fetching search history:', fetchError);
        } else {
          setSearchHistory(data);
        }
      }
    }
  }, [userId]);

  const fetchUserData = useCallback(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUserId(user.id);
        const { data: accountData, error: accountError } = await supabase
          .from('accounts')
          .select('id, can_view_bom_components, can_view_full_stock')
          .eq('id', user.id)
          .single();

        if (accountError) throw accountError;
        setAccountId(accountData.id);
        setCanViewBOMComponents(accountData.can_view_bom_components);
        setCanViewFullStock(accountData.can_view_full_stock);

        const { data: historyData, error: historyError } = await supabase
          .from('search_history')
          .select('part_number, search_type')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false })
          .limit(10);

        if (historyError) throw historyError;
        setSearchHistory(historyData);

        const { data: favoritesData, error: favoritesError } = await supabase
          .from('favorites')
          .select('part_number')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false });

        if (favoritesError) throw favoritesError;
        setFavorites(favoritesData.map(item => item.part_number));
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      showError(error, 'Failed to fetch user data. Please try again later.');
    }
  }, []);

  const checkCustomerPartNumber = async (partNumber) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No authenticated user found');
        return null;
      }
  
      const { data, error } = await supabase
        .from('part_number_mappings')
        .select('mhs_part_number')
        .eq('customer_part_number', partNumber)
        .eq('user_id', user.id)  // This line ensures we only check mappings for the current user
        .single();
  
      if (error) {
        if (error.code === 'PGRST116') {
          // No matching customer part number found
          return null;
      }
      throw error;
    }

    return data.mhs_part_number;
  } catch (error) {
    console.error('Error checking customer part number:', error);
    return null;
  }
};

const handleSearch = useCallback(async (e) => {
  e.preventDefault();
  setLoading(true);
  setResult(null);
  setSearchResults([]);
  setCrossReferences(null);
  setProductNotAllowed(false);

  try {
    let mhsPartNumber = searchTerm;

    // Check if the search term is a valid customer part number
    if (searchType === 'exact') {
      const customerMhsPartNumber = await checkCustomerPartNumber(searchTerm);
      if (customerMhsPartNumber) {
        mhsPartNumber = customerMhsPartNumber;
        showNotification('Customer part number found. Searching for corresponding MHS part.', 'info');
      }
    }

    const payload = searchType === 'exact'
      ? { partNumber: mhsPartNumber, account_id: accountId }
      : { searchTerm, searchType, account_id: accountId };

    const response = await fetch('/api/enquire/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const text = await response.text();
      console.error('Server response:', text);
      throw new Error(`HTTP error! status: ${response.status}, body: ${text}`);
    }

    let data;
    try {
      data = await response.json();
    } catch (jsonError) {
      console.error('JSON parsing error:', jsonError);
      const text = await response.text();
      console.error('Raw response:', text);
      throw new Error(`Failed to parse JSON: ${jsonError.message}. Raw response: ${text}`);
    }

    if (searchType === 'exact') {
      if (data.cross_references) {
        setCrossReferences(data.cross_references);
        showNotification('Cross-references found for the given part number.', 'success');
      } else if (data.bom_code || data.prod_code) {
        setResult(data);
        showNotification('Part information retrieved successfully.', 'success');
        addToSearchHistory(searchTerm, 'exact');
      } else {
        showNotification('No exact match found for the given part number.', 'info');
      }
    } else {
      setSearchResults(Array.isArray(data) ? data : []);
      if (data.length === 0) {
        showNotification('No results found for your search.', 'info');
      } else {
        showNotification(`Found ${data.length} results`, 'success');
        addToSearchHistory(searchTerm, searchType);
      }
    }
  } catch (error) {
    if (error.message.includes('Product Not Allowed')) {
      setProductNotAllowed(true);
      showNotification('This product is not available for enquiry or purchase.', 'error');
    } else if (error.message.includes('Database error')) {
      showError(error, 'A database connection error occurred. Please try again later or contact support.');
      console.error('Database error:', error.message);
    } else {
      showError(error, 'An error occurred while searching. Please try again.');
      console.error('Error:', error);
    }
  } finally {
    setLoading(false);
    setIsSearching(false);
  }
}, [searchTerm, searchType, accountId, addToSearchHistory]);

const handleResultClick = useCallback((partNumber) => {
  setSearchTerm(partNumber);
  setSearchType('exact');
  handleSearch({ preventDefault: () => {} });
}, [handleSearch]);

const handleAddToFavorites = async (term) => {
  if (userId && !favorites.includes(term)) {
    const { error } = await supabase
      .from('favorites')
      .insert({ user_id: userId, part_number: term });

    if (error) {
      console.error('Error adding to favorites:', error);
      showError(error, 'Failed to add to favorites');
    } else {
      setFavorites(prevFavorites => [term, ...prevFavorites]);
      showNotification(`Added "${term}" to favorites`, 'success');
    }
  }
};

const handleRemoveFromFavorites = async (term) => {
  if (userId) {
    const { error } = await supabase
      .from('favorites')
      .delete()
      .eq('user_id', userId)
      .eq('part_number', term);

    if (error) {
      console.error('Error removing from favorites:', error);
      showError(error, 'Failed to remove from favorites');
    } else {
      setFavorites(prevFavorites => prevFavorites.filter(fav => fav !== term));
      showNotification(`Removed "${term}" from favorites`, 'success');
    }
  }
};

const handleRemoveFromHistory = async (term) => {
  if (userId) {
    const { error } = await supabase
      .from('search_history')
      .delete()
      .eq('user_id', userId)
      .eq('part_number', term);

    if (error) {
      console.error('Error removing from history:', error);
      showError(error, 'Failed to remove from history');
    } else {
      setSearchHistory(prevHistory => prevHistory.filter(item => item.part_number !== term));
      showNotification(`Removed "${term}" from search history`, 'success');
    }
  }
};

const addToCart = (item) => {
  const cartItem = {
    partNumber: item.prod_code || item.bom_code,
    description: item.Description || item.bom_description,
    quantity: 1,
    price: item.buy_price,
    isBOM: !!item.bom_code
  };

  const existingCart = JSON.parse(localStorage.getItem('cart') || '[]');
  const updatedCart = [...existingCart, cartItem];
  localStorage.setItem('cart', JSON.stringify(updatedCart));

  showNotification('Item added to cart successfully', 'success');
};

const handleSignOut = async () => {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    showNotification('Successfully signed out!', 'success');
    history.push('/login');
  } catch (error) {
    showError(error, error.error_description || error.message);
  }
};

useEffect(() => {
  fetchUserData();
  const params = new URLSearchParams(location.search);
  const partNumberParam = params.get('partNumber');
  if (partNumberParam) {
    setSearchTerm(partNumberParam);
    handleSearch({ preventDefault: () => {} });
  }
}, [location, handleSearch, fetchUserData]);

return (
  <div className="min-h-screen bg-[#1a1818]">
    <Navigation onSignOut={handleSignOut} />
    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <motion.h1 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-2xl md:text-4xl font-extrabold text-[#fed402] text-center mb-6 md:mb-12"
      >
        Part Enquiry
      </motion.h1>
      
      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-[#fed402] rounded-xl shadow-lg p-4 md:p-6 mb-6 md:mb-8"
      >
        <form onSubmit={handleSearch} className="flex flex-col md:flex-row md:items-center">
          <input
            type="text"
            required
            className="w-full md:flex-grow px-4 py-2 md:py-3 mb-2 md:mb-0 bg-white border-2 border-[#1a1818] rounded-lg md:rounded-l-lg focus:outline-none focus:ring-2 focus:ring-[#1a1818] focus:border-transparent text-[#1a1818] placeholder-gray-500"
            placeholder="Enter Part Number or Search Term"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="flex flex-col w-full space-y-2 md:flex-row md:space-y-0 md:space-x-2 md:w-auto md:ml-2">
            <select
              className="w-full md:w-auto px-4 py-2 md:py-3 bg-white border-2 border-[#1a1818] md:border-l-0 focus:outline-none focus:ring-2 focus:ring-[#1a1818] focus:border-transparent text-[#1a1818]"
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
            >
              <option value="exact">Exact Part Number</option>
              <option value="fuzzy">Fuzzy Part Number</option>
              <option value="description">Description</option>
            </select>
            <button
              type="submit"
              className="w-full md:w-auto inline-flex items-center justify-center py-2 md:py-3 px-4 md:px-6 border-2 border-[#1a1818] rounded-lg md:rounded-r-lg text-[#1a1818] bg-[#fed402] hover:bg-[#1a1818] hover:text-[#fed402] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1a1818] transition-colors duration-200"
              disabled={loading}
            >
              {loading ? (
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                >
                  <Search className="w-5 h-5" />
                </motion.div>
              ) : (
                <Search className="w-5 h-5" />
              )}
              <span className="ml-2">{loading ? 'Searching...' : 'Search'}</span>
            </button>
          </div>
        </form>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="mb-4"
      >
        <button
          onClick={() => setIsHistoryVisible(!isHistoryVisible)}
          className="w-full flex items-center justify-center py-2 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
        >
          {isHistoryVisible ? (
            <>
              Hide Search History and Favorites <ChevronUp className="ml-2 w-4 h-4" />
            </>
          ) : (
            <>
              Show Search History and Favorites <ChevronDown className="ml-2 w-4 h-4" />
            </>
          )}
        </button>
      </motion.div>

      <AnimatePresence>
        {isHistoryVisible && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <SearchHistoryAndFavorites 
              searchHistory={searchHistory}
              favorites={favorites}
              onSelect={handleResultClick}
              onRemoveFromHistory={handleRemoveFromHistory}
              onRemoveFromFavorites={handleRemoveFromFavorites}
            />
          </motion.div>
        )}
      </AnimatePresence>
      
      {productNotAllowed && (
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 md:mb-8"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <svg className="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
              </svg>
            </div>
            <div>
              <p className="font-bold">Product Not Allowed</p>
              <p>This product is not available for enquiry or purchase.</p>
            </div>
          </div>
        </motion.div>
      )}

      {searchResults.length > 0 && (
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-xl shadow-xl overflow-hidden mb-6 md:mb-8"
        >
          <div className="p-4 md:p-6">
            <h2 className="text-xl md:text-2xl font-bold text-[#1a1818] mb-4">Search Results</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Image
                    </th>
                    <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Part Number
                    </th>
                    <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {searchResults.map((result, index) => (
                    <tr 
                      key={index} 
                      className="hover:bg-gray-100 cursor-pointer transition duration-150 ease-in-out" 
                      onClick={() => handleResultClick(result.prod_code)}
                    >
                      <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                        <img 
                          src={result.small_image_url || 'https://syd1.digitaloceanspaces.com/cdn-assets.mhs/images/placeholders/mhs_placeholder.png'} 
                          alt={result.prod_code} 
                          className="h-8 w-8 md:h-10 md:w-10 rounded-full object-cover"
                          onError={(e) => {
                            e.target.onerror = null;
                              e.target.src = 'https://syd1.digitaloceanspaces.com/cdn-assets.mhs/images/placeholders/mhs_placeholder.png';
                            }}
                          />
                        </td>
                        <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                          <div className="text-xs md:text-sm font-medium text-gray-900">{result.prod_code}</div>
                        </td>
                        <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                          <div className="text-xs md:text-sm text-gray-500">{result.Description}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </motion.div>
        )}
  
        {crossReferences && (
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-xl shadow-xl overflow-hidden mb-6 md:mb-8"
          >
            <div className="p-4 md:p-6">
            <h2 className="text-xl md:text-2xl font-bold text-[#1a1818] mb-4">Cross References</h2>
              <p className="mb-4 text-sm md:text-base text-gray-600">
                The part number you entered is an alternative part. Here are the corresponding main parts:
              </p>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-2 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Alternate Part</th>
                      <th className="px-2 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Alternate Description</th>
                      <th className="px-2 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Current Part</th>
                      <th className="px-2 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Current Description</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {crossReferences.map((ref, index) => (
                      <tr key={index} className="hover:bg-gray-100 cursor-pointer" onClick={() => handleResultClick(ref.prod_code)}>
                        <td className="px-2 md:px-6 py-4 whitespace-nowrap text-xs md:text-sm">{ref.alt_prod_code}</td>
                        <td className="px-2 md:px-6 py-4 whitespace-nowrap text-xs md:text-sm">{ref.alt_description}</td>
                        <td className="px-2 md:px-6 py-4 whitespace-nowrap text-xs md:text-sm">{ref.prod_code}</td>
                        <td className="px-2 md:px-6 py-4 whitespace-nowrap text-xs md:text-sm">{ref.main_description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </motion.div>
        )}
      
        {result && (
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-[#fed402] rounded-xl shadow-xl overflow-hidden"
          >
            {result.bom_code ? (
              <BOMComponent 
                bom={result}
                addToCart={() => addToCart(result)}
                canViewComponents={canViewBOMComponents}
              />
            ) : (
              <div>
                <div className="px-4 md:px-6 py-6 md:py-8 bg-[#1a1818]">
                  <h2 className="text-2xl md:text-3xl font-bold text-[#fed402] mb-2">{result.prod_code}</h2>
                  <p className="text-[#fed402] opacity-80 text-sm md:text-base">{result.Description}</p>
                </div>
                <div className="p-4 md:p-6 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                  <InfoCard 
                    icon={<Box />} 
                    title="Quantity" 
                    value={
                      <div className="text-sm md:text-base">
                        {canViewFullStock && (
                          <>
                            <p><strong>On Hand:</strong> {result.qty_on_hand} {result.unit_code}</p>
                            <p><strong>Allocated:</strong> {result.qty_allocated} {result.unit_code}</p>
                          </>
                        )}
                        <p><strong>Available:</strong> {result.qty_available} {result.unit_code}</p>
                        {result.order_status && (
                          <div className={`mt-2 p-2 rounded-md ${result.order_status.trim() === 'On Order' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                            <strong>{result.order_status.trim()}</strong>
                          </div>
                        )}
                        {result.qty_available === 0 && !result.order_status && (
                          <NotifyMeButton 
                            partNumber={result.prod_code || result.bom_code}
                            isInStock={false}
                          />
                        )}
                        <p className="text-xs text-gray-500 mt-2 italic">
                          Stock information is not guaranteed to be 100% accurate. If in doubt, please contact us.
                        </p>
                      </div>
                    } 
                    addToCart={() => addToCart(result)} 
                  />
                  <div className="space-y-4">
                    <InfoCard 
                      icon={<DollarSign />} 
                      title="Base Price" 
                      value={result.base_price ? `$${parseFloat(result.base_price).toFixed(2)} per ${result.unit_code}` : 'N/A'} 
                    />
                    {result.buy_price && (
                      <InfoCard 
                        icon={<DollarSign />} 
                        title="Buy Price" 
                        value={<span className="text-green-600 font-bold">${parseFloat(result.buy_price).toFixed(2)} per {result.unit_code}</span>} 
                      />
                    )}
                  </div>
                  <InfoCard 
                    icon={<ImageIcon />} 
                    title="Product Image" 
                    value={
                      <div className="w-full h-48 md:h-64 flex flex-col items-center justify-center">
                        <img 
                          src={result.large_image_url} 
                          alt="Product" 
                          className="max-w-full max-h-full object-contain rounded-lg shadow-md" 
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://syd1.digitaloceanspaces.com/cdn-assets.mhs/images/placeholders/mhs_placeholder.png';
                          }}
                        />
                        <p className="text-xs text-gray-500 mt-2 italic">Similar to illustration</p>
                      </div>
                    } 
                  />
                  <InfoCard 
  icon={<FileText />} 
  title="Specifications" 
  value={
    <div className="space-y-2 text-sm md:text-base">
      {result.specifications && Object.keys(result.specifications).length > 0 ? (
        Object.entries(result.specifications)
          .filter(([key]) => key.toLowerCase() !== 'volume_cc')
          .map(([key, value]) => (
            <SpecificationItem 
              key={key} 
              label={key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} 
              value={typeof value === 'number' ? value.toFixed(2) : value} 
            />
          ))
      ) : (
        <p className="text-gray-500 italic">No specifications available</p>
      )}
    </div>
  } 
/>
                  {result.article_description && (
                    <InfoCard 
                      icon={<Info />} 
                      title="Additional Information" 
                      value={
                        <div className="text-sm md:text-base">
                          <p><strong>Article Description:</strong> {result.article_description}</p>
                          <p><strong>Product Group:</strong> {result.prod_group}</p>
                        </div>
                      } 
                    />
                  )}
                </div>
              </div>
            )}
            <div className="px-4 md:px-6 py-4">
              <button
                onClick={() => handleAddToFavorites(result.prod_code || result.bom_code)}
                className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-[#1a1818] bg-[#fed402] hover:bg-[#e0bc02] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402]"
              >
                <Star className="mr-2" size={16} />
                Add to Favorites
              </button>
            </div>
          </motion.div>
        )}
        

        <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.5 }}
        className="mt-8 p-4 bg-gray-800 rounded-lg text-gray-300 text-sm"
      >
        <p className="font-semibold mb-2">Disclaimer:</p>
        <p>
          While we strive to provide accurate and up-to-date information, please note that the content on this page may contain omissions or errors. 
          The information presented here is for general guidance only and should not be relied upon as complete or exact. 
          For the most current and accurate details, please contact our customer support team.
        </p>
      </motion.div>
      
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-8"
        >
          <button
            onClick={() => history.push('/dashboard')}
            className="w-full flex items-center justify-center py-3 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </button>
        </motion.div>

        <AnimatePresence>
          {isSearching && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <LoadingOverlay message="Searching for parts..." />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default PartEnquiry;