import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Trash2, Plus, Minus, ShoppingBag } from 'lucide-react';
import { toast } from 'react-toastify';
import { supabase } from '../supabaseClient';
import Navigation from './Navigation';
import { showNotification, showError } from '../utils/notificationHandler';

const CartItem = ({ item, updateQuantity, removeFromCart }) => (
  <div className="bg-white rounded-lg shadow-md p-4 mb-4">
    <div className="flex justify-between items-center">
      <div>
        <h3 className="text-lg font-semibold text-[#1a1818]">{item.partNumber}</h3>
        <p className="text-sm text-gray-600">{item.description}</p>
      </div>
      <div className="flex items-center">
        <button
          onClick={() => updateQuantity(item.partNumber, item.quantity - 1)}
          className="text-[#1a1818] hover:text-[#fed402] transition-colors duration-200"
        >
          <Minus size={20} />
        </button>
        <span className="mx-2 text-[#1a1818] font-semibold">{item.quantity}</span>
        <button
          onClick={() => updateQuantity(item.partNumber, item.quantity + 1)}
          className="text-[#1a1818] hover:text-[#fed402] transition-colors duration-200"
        >
          <Plus size={20} />
        </button>
        <button
          onClick={() => removeFromCart(item.partNumber)}
          className="ml-4 text-red-500 hover:text-red-700 transition-colors duration-200"
        >
          <Trash2 size={20} />
        </button>
      </div>
    </div>
  </div>
);

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      setCartItems(JSON.parse(storedCart));
    }
  }, []);

  const updateQuantity = (partNumber, newQuantity) => {
    if (newQuantity < 1) return;
    const updatedCart = cartItems.map(item =>
      item.partNumber === partNumber ? { ...item, quantity: newQuantity } : item
    );
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const removeFromCart = (partNumber) => {
    const updatedCart = cartItems.filter(item => item.partNumber !== partNumber);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    showNotification('Item removed from cart successfully', 'success');
  };

  const handleProceedToCheckout = () => {
    if (cartItems.length > 0) {
      history.push('/checkout');
    } else {
      showNotification('Your cart is empty. Add items before proceeding.', 'error');
    }
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      showNotification('Successfully signed out!', 'success');
      history.push('/login');
    } catch (error) {
      showError(error, 'An error occurred while signing out. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-[#1a1818]">
      <Navigation onSignOut={handleSignOut} />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-extrabold text-[#fed402] text-center mb-12"
        >
          Quote Cart
        </motion.h1>
        
        {cartItems.length === 0 ? (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-center text-[#fed402]"
          >
            Your cart is empty.
          </motion.p>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {cartItems.map(item => (
              <CartItem
                key={item.partNumber}
                item={item}
                updateQuantity={updateQuantity}
                removeFromCart={removeFromCart}
              />
            ))}
            <div className="mt-8 space-y-4">
              <button
                onClick={handleProceedToCheckout}
                className="w-full bg-[#fed402] text-[#1a1818] py-2 px-4 rounded-lg font-semibold hover:bg-[#e0bc02] transition-colors duration-200 flex items-center justify-center"
              >
                <ShoppingBag className="mr-2" />
                Proceed to Checkout
              </button>
            </div>
          </motion.div>
        )}
        
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-8"
        >
          <button
            onClick={() => history.push('/dashboard')}
            className="w-full flex items-center justify-center py-3 px-4 border border-[#fed402] rounded-lg shadow-sm text-sm font-medium text-[#fed402] bg-[#1a1818] hover:bg-[#fed402] hover:text-[#1a1818] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fed402] transition-colors duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Cart;